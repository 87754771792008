import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { MdFiberManualRecord } from 'react-icons/md';

import notAvatar from '~/assets/avatar.png';

import {
  Detail,
  DetailItem,
  GriStatics,
  JourneyContainer,
  PageCount,
} from './styles';

function Daily({ statics, journeys, log, map }) {
  const { max, avg, distance, liters, fuelprice, total } = statics;
  const [markerN, setMarkerN] = useState({});

  async function getAddress(lat, lng) {
    if (lat && lng) {
      try {
        const { data } = await axios.get(
          `https://location.georastreamento.com.br/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=16`
        );
        const { road, city_district, town, city, state } = data.address;
        return `${road || city_district}, ${city || town} - ${state}`;
      } catch (error) {
        return 'Carregando endereço...';
      }
    }
    return '';
  }

  async function setDailyMap(coordinate) {
    if (!_.isEmpty(markerN)) {
      markerN.setMap(null);
    }
    const infowindow = await new window.google.maps.InfoWindow({
      content: `<div>
      <div><b>Coordenada:</b> ${coordinate.lat}, ${coordinate.lng}</div>
      <div><b>Iginição:</b> ${
        coordinate.iginition ? 'Ligada' : 'Desligada'
      }</div>
      <div><b>Momento:</b> ${moment(coordinate.date).format(
        'DD/MM/YYYY hh:mm:ss'
      )}</div>
      <div><b>Momento:</b> ${await getAddress(
        coordinate.lat,
        coordinate.lng
      )}</div>
      </div>`,
    });

    const markerNew = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
      map,
    });
    markerNew.setVisible(false);
    setMarkerN(markerNew);
    // console.log(marker);
    infowindow.open(map, markerNew);
    map.setZoom(map.getZoom());
  }
  return (
    <Detail>
      <DetailItem>
        <header>Estatisticas</header>
        <GriStatics>
          <div>
            <header>
              <strong>{max ? parseFloat(max).toFixed(0) : '-'}</strong>
              <small>km/h</small>
            </header>
            <footer>
              <small>Velocidade Máxima</small>
            </footer>
          </div>
          <div>
            <header>
              <strong>{avg ? parseFloat(avg).toFixed(0) : '-'}</strong>
              <small>km/h</small>
            </header>
            <footer>
              <small>Velocidade Média</small>
            </footer>
          </div>
          <div>
            <header>
              <strong>
                {distance ? (parseFloat(distance) / 1000).toFixed(0) : '-'}
              </strong>
              <small>km</small>
            </header>
            <footer>
              <small>Distância (apox.)</small>
            </footer>
          </div>
          <div>
            <header>
              <strong>{liters ? parseFloat(liters).toFixed(2) : '-'}</strong>
              <small>L</small>
            </header>
            <footer>
              <small>Litros (aporx.)</small>
            </footer>
          </div>
          <div>
            <header>
              <small>R$</small>
              <strong>
                {fuelprice ? parseFloat(fuelprice).toFixed(2) : '-'}
              </strong>
            </header>
            <footer>
              <small>Preço por litro</small>
            </footer>
          </div>
          <div>
            <header>
              <small>R$</small>
              <strong>{total ? parseFloat(total).toFixed(2) : '-'}</strong>
            </header>
            <footer>
              <small>Total gasto (aprox.)</small>
            </footer>
          </div>
          <PageCount>
            <MdFiberManualRecord size={10} color="#333" />
            <MdFiberManualRecord size={10} color="#dbdbdb" />
            <MdFiberManualRecord size={10} color="#dbdbdb" />
          </PageCount>
        </GriStatics>
      </DetailItem>
      <DetailItem>
        <header>Jornadas</header>
        {journeys.map((journey) => (
          <JourneyContainer>
            <img src={notAvatar} alt={journey.name} />
            <aside>
              <strong>{journey.name}</strong>
              <small>{journey.total}</small>
            </aside>
          </JourneyContainer>
        ))}
        <PageCount>
          <MdFiberManualRecord size={10} color="#dbdbdb" />
          <MdFiberManualRecord size={10} color="#333" />
          <MdFiberManualRecord size={10} color="#dbdbdb" />
        </PageCount>
      </DetailItem>
      <DetailItem>
        <Timeline>
          {log.map((item, index) =>
            item.event === 'ON' ? (
              <TimelineEvent
                style={{ backgroundColor: '#e8f4f0', cursor: 'pointer' }}
                key={`timeline_${index}`}
                bubbleStyle={{
                  borderColor: '#4A6971',
                  backgroundColor: '#e8f4f0',
                }}
                title="Ligou o carro"
                subtitle={item.address}
                createdAt={moment(item.date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                  'HH:mm:ss'
                )}
                onIconClick={() => setDailyMap({ ...item, iginition: true })}
                onClick={() => setDailyMap({ ...item, iginition: true })}
                icon={<i className="material-icons md-18 md-dark" />}
              />
            ) : (
              <TimelineEvent
                key={index}
                style={{ backgroundColor: '#f5f5f5', cursor: 'pointer' }}
                bubbleStyle={{
                  borderColor: '#777575',
                  backgroundColor: '#f5f5f5',
                }}
                title={`Desligou o carro | ${
                  !item.odometer
                    ? 'N/D'
                    : `Percorreu ${(
                        item.odometer -
                        (
                          log[index + 1] || {
                            odometer: 0,
                          }
                        ).odometer
                      ).toFixed(1)}`
                } km`}
                subtitle={item.address}
                createdAt={moment(item.date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                  'HH:mm:ss'
                )}
                onIconClick={() =>
                  setDailyMap({
                    ...item,
                    iginition: false,
                  })
                }
                onClick={() =>
                  setDailyMap({
                    ...item,
                    iginition: false,
                  })
                }
                icon={<i className="material-icons md-18" />}
              />
            )
          )}
        </Timeline>
        <PageCount>
          <MdFiberManualRecord size={10} color="#dbdbdb" />
          <MdFiberManualRecord size={10} color="#dbdbdb" />
          <MdFiberManualRecord size={10} color="#333" />
        </PageCount>
      </DetailItem>
    </Detail>
  );
}

Daily.propTypes = {
  statics: PropTypes.objectOf.isRequired,
  journeys: PropTypes.arrayOf.isRequired,
  log: PropTypes.arrayOf.isRequired,
  map: PropTypes.objectOf.isRequired,
};

export default Daily;
