import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';

import Block from '~/pages/Block';
import BlockForm from '~/pages/Block/Form';
import Command from '~/pages/Command';
import Daily from '~/pages/Daily';
import Dashboard from '~/pages/Dashboard';
import DetailCar from '~/pages/DetailCar';
import Forgot from '~/pages/Forgot';
import Monitoring from '~/pages/Monitoring';
import Notification from '~/pages/Notification';
import Profile from '~/pages/Profile';
import Share from '~/pages/Share';
import SignIn from '~/pages/SignIn';
import { Creators as AuthActions } from '~/store/modules/auth/duck';

import api from '../services/api';
import Route from './Route';

export default function Routes() {
  const dispatch = useDispatch();

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          dispatch(AuthActions.signOut());
        }
      }
      return Promise.reject(error.response);
    }
  );

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgot" exact component={Forgot} />
      <Route path="/share/:token" isShare exact component={Share} />
      <Route path="/dashboard" isPrivate component={Dashboard} />
      <Route path="/monitoring" isPrivate component={Monitoring} />
      <Route path="/notification" isPrivate component={Notification} />
      <Route path="/profile" isPrivate component={Profile} />
      <Route path="/detailCar/:id" isPrivate component={DetailCar} />
      <Route path="/daily" isPrivate component={Daily} />
      <Route path="/block/form/:id" isPrivate component={BlockForm} />
      <Route path="/block/form" isPrivate component={BlockForm} />
      <Route path="/block" isPrivate component={Block} />
      <Route path="/command" isPrivate component={Command} />
    </Switch>
  );
}
