import check from '~/assets/map/check.svg';
import referencePointOff from '~/assets/map/referencePointOff.svg';
import referencePointOn from '~/assets/map/referencePointOn.svg';
import trafficOff from '~/assets/map/trafficOff.png';
import trafficOn from '~/assets/map/trafficOn.png';
import widgetMinimap from '~/assets/map/widgetMinimap.svg';
import widgetStreetMap from '~/assets/map/widgetStreetMap.svg';
import zoneOff from '~/assets/map/zoneOff.svg';
import zoneOn from '~/assets/map/zoneOn.svg';
import api from '~/services/api';

export function mapControl(controlDiv, map) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.marginRight = '11px';
  controlUI.style.marginLeft = '11px';
  if (window.location.pathname.indexOf('detailCar') !== -1) {
    controlUI.style.marginBottom = '230px';
  } else if (window.location.pathname === '/daily') {
    controlUI.style.marginBottom = '205px';
  } else if (window.location.pathname === '/command') {
    controlUI.style.marginBottom = '130px';
  } else if (window.location.pathname.indexOf('share') !== -1) {
    controlUI.style.marginBottom = '240px';
  } else {
    controlUI.style.marginBottom = '64px';
  }
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  // controlUI.style.marginBottom = '10px';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '62px';
  controlUI.style.width = '70px';
  controlUI.title = 'Clique para mudar o tipo de mapa';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '58px';
  controlImage.style.width = '66px';
  controlImage.style.borderRadius = '10px';
  controlImage.src = widgetMinimap;
  controlUI.appendChild(controlImage);

  const controlText = document.createElement('div');
  controlText.style.color = '#FFF';
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlText.style.fontSize = '13px';
  controlText.style.lineHeight = '62px';
  controlText.style.paddingLeft = '5px';
  controlText.style.paddingRight = '5px';
  controlText.style.marginTop = '-42px';
  controlText.innerHTML = 'Mapa';
  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', function () {
    controlImage.src =
      map.mapTypeId === 'roadmap' ? widgetStreetMap : widgetMinimap;
    controlUI.style.borderColor = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    controlText.innerHTML = map.mapTypeId === 'roadmap' ? 'Satélite' : 'Mapa';
    controlText.style.color = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    map.mapTypeId === 'roadmap'
      ? map.setMapTypeId('hybrid')
      : map.setMapTypeId('roadmap');
  });
}

export function trafficControl(controlDiv, map) {
  const trafficLayer = new window.google.maps.TrafficLayer();
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '40px';
  controlUI.style.width = '40px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.style.display = 'flex';
  controlUI.style.alignItems = 'center';
  controlUI.style.justifyContent = 'center';
  controlUI.title = 'Clique para mostrar o trânsito';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '35px';

  controlImage.style.padding = '2px 4px';
  controlImage.src = trafficOff;

  controlUI.appendChild(controlImage);

  controlUI.addEventListener('click', function () {
    if (trafficLayer.getMap() == null) {
      trafficLayer.setMap(map);
      controlImage.src = trafficOn;
    } else {
      trafficLayer.setMap(null);
      controlImage.src = trafficOff;
    }
  });
}

export function referencePointControl(controlDiv, map) {
  const infowindow = new window.google.maps.InfoWindow();
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '40px';
  controlUI.style.width = '40px';
  controlUI.style.display = 'flex';
  controlUI.style.alignItems = 'center';
  controlUI.style.justifyContent = 'center';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar os pontos de referência';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '35px';
  controlImage.style.padding = '3px 3px';
  controlImage.src = referencePointOff;

  controlUI.appendChild(controlImage);
  let visibleReference = false;
  controlUI.addEventListener('click', async () => {
    visibleReference = !visibleReference;
    if (visibleReference) {
      controlImage.src = referencePointOn;
      const response = await api.get('/geometries?type=point');
      const { data } = response;
      data.map((query) => {
        const { geojson } = query;
        geojson.properties = { title: query.name };
        map.data.addGeoJson(geojson);
      });
      map.data.addListener('click', (event) => {
        const feat = event.feature;
        const html = `<div>${feat.getProperty('title')}</div>`;
        infowindow.setContent(html);
        infowindow.setPosition(event.latLng);
        infowindow.setOptions({
          pixelOffset: new window.google.maps.Size(0, -34),
        });
        infowindow.open(map);
      });
    } else {
      map.data.forEach(function (feature) {
        map.data.remove(feature);
      });
      controlImage.src = referencePointOff;
    }
  });
}

export function zoneControl(controlDiv, map) {
  const infowindow = new window.google.maps.InfoWindow();
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '40px';
  controlUI.style.width = '40px';
  controlUI.style.display = 'flex';
  controlUI.style.alignItems = 'center';
  controlUI.style.justifyContent = 'center';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar as cercas';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '35px';
  controlImage.style.padding = '3px 3px';
  controlImage.src = zoneOff;

  controlUI.appendChild(controlImage);

  let visibleZone = false;
  controlUI.addEventListener('click', async function () {
    visibleZone = !visibleZone;
    if (visibleZone) {
      const response = await api.get('/geometries?type=fence');
      const { data } = response;
      data.map((query) => {
        const { geojson } = query;
        if (geojson.geometry.type === 'Point') {
          geojson.geometry.coordinates.splice(0, 1);
        }
        geojson.properties = { title: query.name };
        map.data.addGeoJson(geojson);
        const html = `<div>${query.name}</div>`;
        infowindow.setContent(html);
        // infowindow.setPosition(event.latLng);
        infowindow.setOptions({
          pixelOffset: new window.google.maps.Size(0, -34),
        });
        infowindow.open(map);
      });
      //   map.data.addListener('click', function(event) {
      //     var feat = event.feature;
      //     var html = `<div>${feat.getProperty('title')}</div>`
      //     infowindow.setContent(html);
      //     infowindow.setPosition(event.latLng);
      //     infowindow.setOptions({pixelOffset: new window.google.maps.Size(0,-34)});
      //     infowindow.open(map);
      //  });
      controlImage.src = zoneOn;
    } else {
      map.data.forEach(function (feature) {
        map.data.remove(feature);
      });
      controlImage.src = zoneOff;
    }
  });
}

export function saveZone(controlDiv, map, props, event, drawingManager) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#555555';
  controlUI.style.borderRadius = '500px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'SALVAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', function () {
    props.setDrawingMode();
    props.setViewModal();
    event.setMap(null);
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
    drawingManager.setOptions({
      drawingControl: true,
    });
  });
}

export function cancelZone(controlDiv, map, props, event, drawingManager) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#E33939';
  controlUI.style.borderRadius = '500px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'CANCELAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', function () {
    props.setDrawingMode();
    event.setMap(null);
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
    drawingManager.setOptions({
      drawingControl: true,
    });
  });
}
