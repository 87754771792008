import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import markerAlert from '~/assets/marker/markerAlert.svg';
import markerRun from '~/assets/marker/markerRun.svg';
import markerStop from '~/assets/marker/markerStop.svg';
import GoogleMaps from '~/components/GoogleMap';
import SelectTracker from '~/components/SelectTracker';
import { Marker } from '~/pages/Monitoring/styles';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import Actions from './Actions';
import History from './History';
import Modal from './Modal';
import { Container, Filter, Content } from './styles';
import Tracker from './Tracker';

function Command() {
  const dispatch = useDispatch();
  const trackerId = useSelector((state) => state.command.tracker_id);
  const buffer = useSelector((state) => state.command.buffer);
  const [mapConst, setMapConst] = useState();
  const [mapsConst, setMapsConst] = useState();
  const [alert, setAlert] = useState(null);
  const [marker, setMarker] = useState(markerStop);

  function mapLoad(map, maps) {
    setMapConst(map);
    setMapsConst(maps);
  }
  function getTrackerData(tracker_id) {
    dispatch(CommandActions.fetchCommandRequest(tracker_id));
  }

  function getMapBounds(map, maps, car) {
    if (car && maps && map) {
      const bounds = new maps.LatLngBounds();

      bounds.extend(new maps.LatLng(car.lat, car.lng));
      return bounds;
    }
    return null;
  }

  useEffect(() => {
    if (!_.isEmpty(buffer) && !_.isEmpty(mapConst)) {
      mapConst.setCenter(new window.google.maps.LatLng(buffer.lat, buffer.lng));
      mapConst.setZoom(16);
      if (buffer.status === 'DANGER') {
        setMarker(markerAlert);
      } else if (buffer.ignition) {
        setMarker(markerRun);
      } else {
        setMarker(markerStop);
      }

      if (buffer.status === 'DISCONNECTED') {
        setAlert('DISCONNECTED');
      } else if (buffer.alert_on) {
        setAlert('ALERT');
      } else {
        setAlert(null);
      }
    }
  }, [buffer]);

  useEffect(() => {
    if (trackerId) {
      const interval = setInterval(() => {
        dispatch(CommandActions.fetchCommandBufferRequest(trackerId));
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [trackerId]);

  useEffect(() => {
    dispatch(CommandActions.clearTrackerId());
  }, []);

  return (
    <Container>
      <Filter>
        <SelectTracker
          changeTracker={(tracker) => getTrackerData(tracker.id)}
        />
      </Filter>
      <GoogleMaps loadMap={(map, maps) => mapLoad(map, maps)}>
        {!_.isEmpty(buffer) && (
          <Marker
            key={buffer.tracker_id}
            alert={alert}
            rotate={buffer.angle}
            lat={buffer.lat}
            lng={buffer.lng}
          >
            <div>{buffer.label}</div>
            <img src={marker} alt="carros" />
          </Marker>
        )}
      </GoogleMaps>
      {trackerId && (
        <Content>
          <Tracker />
          <Actions />
        </Content>
      )}
      <Modal />
    </Container>
  );
}

export default Command;
