import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #245378;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    > small {
      position: absolute;
      bottom: 5px;
      left: 5px;
      color: #fff;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin: 5px;

    h2 {
      color: #eee;
      font-size: 20px;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    /* input {
      flex: 1;
      border: 1px solid #eee;
      padding: 10px 15px;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 5px;
      opacity: 0.8;
    } */

    p {
      padding: 10px;
      background-color: #eee;
      border-radius: 5px;
      margin-bottom: 10px;

      div {
        margin: 15px;
      }
    }

    button {
      background-color: #333333;
      color: #eee;
      height: 50px;
      border: none;
      border-radius: 5px;
    }

    span {
      color: red;
    }
    > a {
      color: #eee;
      font-size: 15px;
      margin: 10px auto;
      text-decoration: none;
    }
  }
`;

export const Image = styled.img`
  height: 92px;
  margin-bottom: 20px;
`;
