import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonStyled from '~/components/Buttons/SimpleButton';
import MaskedInput from '~/components/Inputs/MaskedInput';
import PasswordInput from '~/components/Inputs/PasswordInput';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
  Field,
} from './styles';

function Modal() {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.command.tracker);
  const formik = useFormik({
    initialValues: {
      odometer: '',
      password: '',
    },
    validate: (value) => {
      const err = {};
      if (!value.odometer) err.odometer = 'Campo obrigatório!';
      if (!value.password) err.password = 'Campo obrigatório!';
      return err;
    },
    onSubmit: (data) => {
      let msg;
      if (device.model === 'SUNTECH') {
        msg = `ST300CMD;${device.imei};02;SetOdometer=${data.odometer}000`;
      } else if (device.model === 'QUECLINK') {
        msg = `AT+GTCFG=gv75,gv75,gv75,1,${data.odometer},,,3F,0,,7DFF,,1,0,300,0,1,0,0,1F,0,FFFF$`;
      } else if (device.model === 'VITANA') {
        msg = `0000,610,${data.odometer}000`; // 610 – Set Initial Mileage
      }

      dispatch(
        CommandActions.createCommand({
          phone: device.phone,
          command: msg,
          value: 'odometerUpdate',
          tracker_id: id,
          type: 'ODOMETER',
          password: data.password,
        })
      );
    },
  });

  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }

  return (
    <ModalContainer onSubmit={formik.handleSubmit}>
      <Title>Odômetro</Title>
      <Descripition>
        <Field>
          <div>Odômetro</div>
          <MaskedInput
            required
            placeholder="Digite o odômetro"
            mask="999999"
            name="odometer"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.odometer}
            error={!!(formik.touched.odometer && formik.errors.odometer)}
          />
        </Field>
        <PasswordInput
          color="#333"
          required
          placeholder="senha"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={!!(formik.touched.password && formik.errors.password)}
        />
      </Descripition>
      <ButtonsContainer>
        <ButtonStyled
          bg="#ff4e4e"
          color="#eee"
          onClick={() => closeModal()}
          text="Cancelar"
        />
        <ButtonStyled type="subbmit" bg="#01659e" color="#eee" text="Enviar" />
      </ButtonsContainer>
    </ModalContainer>
  );
}

export default Modal;
