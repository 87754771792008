import React, { useEffect } from 'react';
import {
  MdDirectionsCar,
  MdMap,
  MdNotifications,
  MdPinDrop,
  MdSmartphone,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

// import lockInverter from '~/assets/lockInverter.svg';
import history from '~/services/history';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';

import { Container, ItemTab } from './styles';

export default function Footer() {
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(BufferActions.bufferRequest());
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <ItemTab
        active={pathname === '/dashboard'}
        onClick={() => history.push('/dashboard')}
      >
        <MdDirectionsCar color="#fff" size={25} />
      </ItemTab>
      <ItemTab
        active={
          pathname === '/monitoring' || pathname.indexOf('/detailCar') !== -1
        }
        onClick={() => history.push('/monitoring')}
      >
        <MdMap color="#fff" size={25} />
      </ItemTab>

      {!auth.passenger && (
        <>
          {' '}
          <ItemTab
            active={pathname === '/notification'}
            onClick={() => history.push('/notification')}
          >
            <MdNotifications color="#fff" size={25} />
          </ItemTab>
          {/* {' '}
          <ItemTab
            active={pathname === '/block'}
            onClick={() => history.push('/block')}
          >
            <img src={lockInverter} alt="Bloqueio agendado" />
          </ItemTab>{' '}
           */}
          <ItemTab
            active={pathname === '/command'}
            onClick={() => history.push('/command')}
          >
            <MdSmartphone color="#fff" size={25} />
          </ItemTab>
          <ItemTab
            active={pathname === '/daily'}
            onClick={() => history.push('/daily')}
          >
            <MdPinDrop color="#fff" size={25} />
          </ItemTab>
        </>
      )}
    </Container>
  );
}
