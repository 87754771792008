import _ from 'lodash';
import React from 'react';

import Filters from './Filters';
import List from './List';
import { Container, Box } from './styles';

export default function Notification() {
  return (
    <Container>
      <Filters />
      <List />
    </Container>
  );
}
