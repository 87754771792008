import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/layouts/auth';
import DefaultLayout from '~/layouts/default';
import ShareLayout from '~/layouts/share';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isShare = false,
  ...rest
}) {
  const signed = useSelector((state) => state.auth.signed);

  if (!isShare) {
    if (!signed && isPrivate) {
      return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
      return <Redirect to="/dashboard" />;
    }
  }

  let Layout;
  if (isShare) {
    Layout = ShareLayout;
  } else if (signed) {
    Layout = DefaultLayout;
  } else {
    Layout = AuthLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
