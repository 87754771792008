import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

function Tracker() {
  const tracker = useSelector((state) => state.command.tracker);
  const loading = useSelector((state) => state.command.loading);
  return (
    <Container>
      {!loading && (
        <>
          <div>
            <b>{tracker.plate}</b>
          </div>
          <div>
            <span>{`${tracker.model} ${tracker.brand}`}</span>
          </div>
          <div>
            <span>{tracker.color}</span>
          </div>
          <div>
            <span>{tracker.year}</span>
          </div>
        </>
      )}
    </Container>
  );
}

export default Tracker;
