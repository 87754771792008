import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ModalContainer = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  padding: 10px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #01659e;
`;

export const Descripition = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    props.output &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50px 5px;
    `}
  >div {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const Field = styled.div`
  width: 100%;
`;
