import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  notificationRequest: ['query'],
  notificationSuccess: ['data'],
  notificationFailure: [],
});

const INITIAL_STATE = {
  notifications: null,
  loading: false,
};

const notificationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const notificationSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  notifications: action.payload.data,
  loading: false,
});

const notificationFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.NOTIFICATION_REQUEST]: notificationRequest,
  [Types.NOTIFICATION_SUCCESS]: notificationSuccess,
  [Types.NOTIFICATION_FAILURE]: notificationFailure,
});
