import { useFormik } from 'formik';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';

import SimpleButton from '~/components/Buttons/SimpleButton';
import MaskedInput from '~/components/Inputs/MaskedInput';
import api from '~/services/api';

import { Container, CarContainer } from './styles';

export default function SelectTracker({ changeTracker }) {
  const [loading, setLoading] = useState(false);
  const [trackers, setTrackers] = useState([]);
  const [trackersImutable, setTrackersImutable] = useState([]);
  const [show, setShow] = useState(false);
  const [carSelected, setCarSelected] = useState('Selecione uma placa');
  const formik = useFormik({
    initialValues: {
      plate: '',
    },
  });
  useEffect(() => {
    async function getTrackers() {
      try {
        await setLoading(true);
        const { data } = await api.get('/trackers?idPlateOnly=true');
        await setTrackers(data);
        await setTrackersImutable(data);
        await setLoading(false);
      } catch (error) {}
    }
    getTrackers();
  }, []);

  useEffect(() => {
    if (show) formik.resetForm();
  }, [show]);
  function setCar(tracker) {
    changeTracker(tracker);
    setCarSelected(tracker.plate);
    setShow(false);
  }

  useEffect(() => {
    console.log(formik.values.plate);
    const newData = matchSorter(
      trackersImutable,
      formik.values.plate || undefined,
      { keys: ['plate'] }
    );
    setTrackers([...newData]);
  }, [formik.values.plate]);

  return (
    <Container>
      <SimpleButton
        loading={loading}
        type="button"
        text={carSelected}
        onClick={() => setShow(!show)}
        bg="rgba(0,0,0, 0.1)"
      />
      {show && (
        <Modal
          visible={show}
          onClickAway={() => setShow(!show)}
          effect="fadeInUp"
          width="300px"
          height="400px"
        >
          <CarContainer>
            <header>
              <div>Item de rastreamento</div>
              <form autoComplete="off">
                <MaskedInput
                  color="#333"
                  id="plate"
                  placeholder="Pesquise pela placa"
                  name="plate"
                  type="text"
                  plate
                  onChange={formik.handleChange}
                  value={formik.values.plate}
                  mask="aaa-9*99"
                />
              </form>
            </header>
            <div>
              {trackers.map((tracker) => (
                <button
                  key={tracker.id}
                  type="button"
                  onClick={() => setCar(tracker)}
                >
                  {tracker.plate}
                </button>
              ))}
            </div>
          </CarContainer>
        </Modal>
      )}
    </Container>
  );
}

SelectTracker.propTypes = {
  changeTracker: PropTypes.func.isRequired,
};
