import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import lock from '~/assets/detailIcons/lock.svg';
import noSignal from '~/assets/noSignalNew.png';
import speedometer from '~/assets/noun_Speedometer_2171226.png';
import batteryRed from '~/assets/status/car-battery-red.png';
import sleepIcon from '~/assets/status/sleepIconSemSobra.svg';
import stopIcon from '~/assets/stopIconSemSobra.png';
import history from '~/services/history';

import {
  Container,
  AvatarContainer,
  Avatar,
  InfoContainer,
  Plate,
  Driver,
  Address,
  StatusContainer,
  Speed,
  StatusIcon,
  Triangle,
  Block,
} from './styles';

export default function TrackerItem({ tracker }) {
  const {
    last_time_on,
    date,
    image,
    label,
    driver,
    status,
    ignition,
    speed,
    tracker_id,
    rpm,
    power_voltage: battery,
    output1,
  } = tracker;

  const batteryRedStyle = {
    backgroundColor: 'red',
    minHeight: `24px`,
    marginTop: `4px`,
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: `4px`,
  };

  /**
   * Calculo tempo parado
   */
  const totalMinutes = moment().diff(moment(last_time_on), 'minutes');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  /**
   * Calculo tempo sem sinal
   */
  const totalMinutesNoSignal = moment().diff(moment(date), 'minutes');
  const hoursNosignal = Math.floor(totalMinutesNoSignal / 60);
  const minutesNoSignal = totalMinutesNoSignal % 60;

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  function getColorCar() {
    if (status === 'SLEEP') {
      return '#EAAA08';
    }
    if (status === 'DANGER') {
      return '#D30807';
    }
    if (status === 'DISCONNECTED') {
      return '#CECECE';
    }
    if (ignition) {
      return '#2C8D14';
    }
    if (!ignition) {
      return '#141414';
    }
    return false;
  }

  const block = !!output1;

  return (
    <Container onClick={() => history.push(`detailCar/${tracker_id}`)}>
      <Triangle color={() => getColorCar()} />
      <AvatarContainer>
        <Avatar src={`${process.env.REACT_APP_SERVER_FILE}/${image}`} />
      </AvatarContainer>
      <InfoContainer>
        <Plate>{label}</Plate>
        <Driver>{driver || 'Sem motorista vinculado'}</Driver>
        <Address>Ver mais...</Address>
      </InfoContainer>
      <StatusContainer>
        {status === 'SLEEP' && (
          <>
            {battery === 0 ? (
              <StatusIcon src={batteryRed} style={batteryRedStyle} />
            ) : (
              <StatusIcon src={sleepIcon} />
            )}
            <Speed>{`${hours}h:${minutes}m`}</Speed>
          </>
        )}
        {status !== 'SLEEP' &&
          (status !== 'DISCONNECTED' ? (
            iff(
              ignition,
              <>
                {!(rpm && rpm < 9999) && <StatusIcon src={speedometer} />}
                <Speed>
                  {rpm && rpm < 9999 ? (
                    <div>
                      <div>{speed} Km/h</div>
                      <div>{rpm} RPM</div>
                    </div>
                  ) : (
                    <>{speed} Km/h</>
                  )}
                </Speed>
              </>,
              <>
                {battery === 0 ? (
                  <StatusIcon src={batteryRed} style={batteryRedStyle} />
                ) : (
                  <StatusIcon src={stopIcon} />
                )}
                <Speed>{`${hours}h:${minutes}m`}</Speed>
              </>
            )
          ) : (
            <>
              {battery === 0 ? (
                <StatusIcon src={batteryRed} style={batteryRedStyle} />
              ) : (
                <StatusIcon src={noSignal} />
              )}
              <Speed>{`${hoursNosignal}h:${minutesNoSignal}m`}</Speed>
            </>
          ))}
      </StatusContainer>

      {block && <Block src={lock} />}
    </Container>
  );
}

TrackerItem.propTypes = {
  tracker: PropTypes.shape({
    last_time_on: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    driver: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    ignition: PropTypes.string.isRequired,
    speed: PropTypes.string.isRequired,
    output1: PropTypes.string.isRequired,
    power_voltage: PropTypes.number,
  }).isRequired,
};
