import { combineReducers } from 'redux';

import auth from './auth/duck';
import block from './block/duck';
import buffer from './buffer/duck';
import command from './command/duck';
import groups from './groups/duck';
import notification from './notification/duck';
import user from './user/duck';

export default combineReducers({
  auth,
  buffer,
  user,
  command,
  notification,
  groups,
  block,
});
