import { useFormik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import notAvatar from '~/assets/avatar.png';
import SimpleButton from '~/components/Buttons/SimpleButton';
import MaskedInput from '~/components/Inputs/MaskedInput';
import PasswordInput from '~/components/Inputs/PasswordInput';
import SimpleInput from '~/components/Inputs/SimpleInput';
import { Creators as AuthActions } from '~/store/modules/auth/duck';
import { Creators as UserActions } from '~/store/modules/user/duck';

import ModalPassword from './ModalPassword';
import { Container, Content, AvatarContainer } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.loading);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const ref = useRef();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      cpf: '',
      image: null,
      password: '',
      confirmPassword: '',
      oldPassword: '',
    },
    validate: (values) => {
      const err = {};
      if (values.password !== values.confirmPassword) {
        err.confirmPassword = 'A senha não confere';
      }
      return err;
    },
    onSubmit: (data) => {
      const formData = new FormData();
      if (data.password !== '' && data.password) {
        if (data.confirmPassword == data.password) {
          formData.append('password', data.password);
          formData.append('editPassword', true);
        } else {
          toast.warn('');
        }
      }
      formData.append('id', data.id);
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('cpf', data.cpf);
      if (preview) {
        formData.append('image', image);
      }
      formData.append('phone', data.phone);
      dispatch(UserActions.userUpdate(formData));
    },
  });

  useEffect(() => {
    if (profile) {
      formik.setValues(profile);
    }
  }, [profile]);

  function handleChangeImage(e) {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  }

  function signOut() {
    dispatch(AuthActions.signOut());
    window.localStorage.removeItem('clientId');
    window.localStorage.removeItem('clientName');
    window.localStorage.filterActive = 'ALL';
  }

  function changePassword() {
    dispatch(UserActions.setVisible());
  }

  return (
    <Container>
      <Content>
        <form onSubmit={formik.handleSubmit}>
          <AvatarContainer>
            <label htmlFor="avatar">
              <img
                src={
                  formik.values.image
                    ? preview ||
                      `${process.env.REACT_APP_SERVER_FILE}/${formik.values.image}`
                    : preview || notAvatar
                }
                alt={formik.values.name || 'Seu avatar'}
              />
              <input
                type="file"
                id="avatar"
                accept="image/*"
                ref={ref}
                onChange={handleChangeImage}
              />
            </label>
          </AvatarContainer>
          <SimpleInput
            disabled
            type="name"
            placeholder="Seu nome"
            name="name"
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <MaskedInput
            disabled
            mask="999.999.999-99"
            placeholder="Seu cpf"
            name="cpf"
            id="cpf"
            onChange={formik.handleChange}
            value={formik.values.cpf}
          />
          <SimpleInput
            disabled
            type="email"
            placeholder="Seu e-mail"
            name="email"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <MaskedInput
            disabled
            mask="(99)9 9999-9999"
            placeholder="Seu telefone"
            name="phone"
            id="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />

          {/* <hr />
          <PasswordInput
            color="#333"
            placeholder="Nova senha"
            name="password"
            id="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <PasswordInput
            color="#333"
            placeholder="Confirme a senha"
            name="confirmPassword"
            id="confirmPassword"
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            value={formik.values.confirmPassword}
          /> */}
        </form>

        <SimpleButton
          type="button"
          bg="#03669f"
          color="#fff"
          text="Mudar senha"
          loading={loading}
          onClick={() => changePassword()}
        />
        <SimpleButton
          type="button"
          onClick={() => signOut()}
          bg="#E33939"
          color="#fff"
          text="Sair"
        />
        <ModalPassword />
      </Content>
      <small>Versão {process.env.REACT_APP_VERSION}</small>
    </Container>
  );
}
