import styled from 'styled-components';

export const Container = styled.div`
  > button {
    width: 100%;
    min-width: 300px;
    height: 40px;
    border: none;
    padding: 0 10px;
    border-radius: 4px;
    font-weight: normal;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    transition: background 0.2s;
    :hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const CarContainer = styled.div`
  display: grid;
  grid-template-rows: 70px 330px;
  header {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #4a6971;
      font-weight: 700;
    }
  }
  > div {
    height: 350px;
    overflow: scroll;
    > button {
      width: 100%;
      height: 40px;
      border: none;
    }
  }
`;
