import _ from 'lodash';
import matchSorter from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import Loading from '~/components/Buttons/animationLoading';
import MaskedInput from '~/components/Inputs/MaskedInput';
import TrackerItem from '~/components/TrackerItem';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';

import { Container, GroupName } from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();

  const trackers = useSelector((state) => state.buffer.trackers);
  // const loading = useSelector((state) => state.buffer.loading);
  const [textFilter, setTextFilter] = useState(undefined);
  const [trackerFiltered, setTrackersFiltered] = useState(trackers || []);
  useEffect(() => {
    dispatch(BufferActions.bufferRequest());
  }, []);
  const { groupName } = window.localStorage;

  function sortPlate(value) {
    setTextFilter(value);
    const filtered = matchSorter(trackers, value, { keys: ['plate'] });
    setTrackersFiltered(filtered);
  }

  useEffect(() => {
    sortPlate(textFilter);
  }, [trackers]);

  return (
    <Container>
      <MaskedInput
        onChange={(e) => sortPlate(e.target.value || undefined)}
        plate
        mask="aaa-9*99"
        background="#fff"
        placeholder="pesquise pela placa"
      />
      {/* {loading && <Loading />} */}
      <GroupName>{groupName && groupName}</GroupName>
      {!_.isEmpty(trackerFiltered) &&
        trackerFiltered.map((tracker) => (
          <TrackerItem tracker={tracker} key={tracker.tracker_id} />
        ))}
    </Container>
  );
}
