import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Clients = styled.div`
  margin: 10px 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  background: #f4f8f9;
  padding: 10px;
  display: grid;
  grid-template-rows: 38px 1fr;
  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a6971;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const Trackers = styled.div`
  margin: 5px 0;
  padding: 0 0 0 5px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.selected ? darken(0.2, '#fff') : '#fff')};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  transition: background 0.2s;
  :hover {
    background: ${darken(0.1, '#fff')};
  }
  > div {
    color: #333;
  }
  > button {
    height: 30px;
    width: 40px;
    border: none;
    cursor: pointer;
    background: #245378;
    border-radius: 0 5px 5px 0;
    transition: background 0.2s;
    :hover {
      background: ${darken(0.1, '#245378')};
    }
  }
`;

export const Li = styled.li`
  background: #fff;
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);

  ${(props) =>
    props.checked &&
    css`
      cursor: pointer;
      :hover {
        background: ${darken(0.1, '#fff')};
      }
    `}
`;

export const Notification = styled.div`
  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #4a6971;
    font-size: 15px;
    font-weight: 600;
    > div {
      text-align: center;
    }
    div:first-child {
      margin-bottom: -15px;
    }
  }
  > ul {
    list-style: none;
  }
`;

export const Box = styled.div`
  background: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
`;
