import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchCommandRequest: ['id'],
  fetchCommandSuccess: ['tracker'],
  fetchCommandBufferRequest: ['id'],
  fetchCommandFailure: [],
  fetchCommandBufferSuccess: ['buffer'],
  fetchCommandBufferFailure: [],
  setModal: [],
  setModalShare: [],
  sendCommand: ['data'],
  successCommand: [],
  failureCommand: [],
  createCommand: ['body'],
  fetchCreateSuccess: [],
  fetchCreateFailure: [],
  clearTrackerId: [],
  setViewModal: ['modalType'],
});

const INITIAL_STATE = {
  modal: false,
  modalShare: false,
  loading: false,
  commands: [],
  tracker: {},
  buffer: {},
  commandList: {},
  tracker_id: null,
};

const SendCommand = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const successCommand = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  modal: false,
});

const failureCommand = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const setModal = (state = INITIAL_STATE) => ({
  ...state,
  modal: !state.modal,
});
const setModalShare = (state = INITIAL_STATE) => ({
  ...state,
  modalShare: !state.modalShare,
});

const fetchCommandRequest = (state = INITIAL_STATE, { id }) => ({
  ...state,
  loading: true,
  tracker_id: id,
});

const fetchCommandSuccess = (
  state = INITIAL_STATE,
  { tracker, commands, commandList, buffer }
) => ({
  ...state,
  commands,
  tracker,
  buffer,
  commandList,
  loadingBuffer: false,
  loading: false,
});

const fetchCommandFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  commands: [],
  tracker: {},
  buffer: {},
  commandList: {},
});

const fetchCommandBufferRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingBuffer: true,
});

const fetchCommandBufferSuccess = (state = INITIAL_STATE, { buffer }) => ({
  ...state,
  buffer,
  loadingBuffer: false,
});

const fetchCommandBufferFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingBuffer: false,
  buffer: {},
});

const createCommand = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchCreateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  viewModal: false,
  modalType: '',
});

const fetchCreateFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const clearTrackerId = (state = INITIAL_STATE) => ({
  ...state,
  tracker_id: null,
  commands: [],
  tracker: {},
  buffer: {},
  commandList: {},
});

const setViewModal = (state = INITIAL_STATE, { modalType }) => ({
  ...state,
  viewModal: !state.viewModal,
  modalType,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_MODAL]: setModal,
  [Types.SET_MODAL_SHARE]: setModalShare,
  [Types.SEND_COMMAND]: SendCommand,
  [Types.SUCCESS_COMMAND]: successCommand,
  [Types.FAILURE_COMMAND]: failureCommand,
  [Types.FETCH_COMMAND_REQUEST]: fetchCommandRequest,
  [Types.FETCH_COMMAND_SUCCESS]: fetchCommandSuccess,
  [Types.FETCH_COMMAND_FAILURE]: fetchCommandFailure,
  [Types.FETCH_COMMAND_BUFFER_REQUEST]: fetchCommandBufferRequest,
  [Types.FETCH_COMMAND_BUFFER_SUCCESS]: fetchCommandBufferSuccess,
  [Types.FETCH_COMMAND_BUFFER_FAILURE]: fetchCommandBufferFailure,
  [Types.CREATE_COMMAND]: createCommand,
  [Types.FETCH_CREATE_SUCCESS]: fetchCreateSuccess,
  [Types.FETCH_CREATE_FAILURE]: fetchCreateFailure,
  [Types.CLEAR_TRACKER_ID]: clearTrackerId,
  [Types.SET_VIEW_MODAL]: setViewModal,
});
