import styled, { css, keyframes } from 'styled-components';

const pulsing = keyframes`
0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0 ,0 , 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 0 ,0 , 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 0 ,0 , 0);
  }
`;

export const Marker = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);

  img {
    height: 30px;
    width: 30px;
    opacity: ${(props) => (props.alert === 'DISCONNECTED' ? 0.5 : 1)};
    transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
  }

  > div {
    height: 17px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: -15px;
    margin-top: -25px;
    position: absolute;
    color: #fff;
    font-weight: 400;
    background: ${(props) => {
      if (props.alert === 'DISCONNECTED') {
        return 'rgba(0, 0, 0, 0.2)';
      }
      if (props.alert === 'ALERT') {
        return 'rgba(255, 20,0, 0.41)';
      }
      return 'rgba(0, 0, 0, 0.41)';
    }};

    ${(props) =>
      props.alert === 'ALERT' &&
      css`
        animation: 2s ${pulsing} infinite;
      `}
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background: #fff;
  height: 40px;
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  top: 60px;
  margin-left: 10px;
  width: calc(100% - 20px);
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
`;

export const ButtonStatus = styled.button`
  border: none;
  width: calc(100% / 6);
  background: ${(props) =>
    props.selected ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  border-bottom: ${(props) =>
    props.selected ? '2px solid #595959' : '2px solid rgba(0, 0, 0, 0.05)'};
  transition: 0.2s;
  opacity: ${(props) => props.disabled && '0.5'};
  > img {
    height: 25px;
    width: 25px;
    margin-bottom: -5px;
  }
  > span {
    width: 21px;
    height: 13px;
    position: absolute;
    margin-left: 16px;
    margin-top: -6px;
    background: #fff;
    border-radius: 6px;
    font-size: 11px;
  }
`;
