import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto 10px;
`;

export const Content = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 10px;

  form {
    margin-bottom: 10px;
    hr {
      width: 100%;
      height: 0;
      border: 1px solid rgba(0, 0, 0, 0.05);
      margin: 10px auto;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  label {
    width: 150px;
    height: 150px;

    margin-top: -75px;
    > img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 3px solid rgba(255, 255, 255, 0.6);
    }
    > input {
      display: none;
    }
  }
`;
