import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: calc(100% - 60px);
  grid-template-rows: 180px 1fr;
  grid-gap: 10px;
  padding: 10px;
  > div {
    height: 100%;
    overflow: scroll;

    > header {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      font-weight: 600;
    }
  }
`;

export const FilterContainer = styled.form`
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'header header' 'tracker type' 'start end' 'button button';
  grid-gap: 5px;
`;

export const HeaderFilter = styled.div`
  grid-area: header;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #245378;
  font-size: 20px;
`;

export const Select = styled.select`
  height: 40px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 4px;
  /* padding-left: 5px; */
  margin-top: 0px;
  color: #999;
  font-size: 14px;
`;
