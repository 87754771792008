import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import mapStyle from '~/assets/map.json';

import { initControls } from './controls';
import { Container } from './styles';

export default function GoogleMap({ children, loadMap, isShare }) {
  const profile = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    try {
      if (window.umami) window.umami.trackEvent(profile.name, 'map.created');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.info(err.message);
    }
  }, []);

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,

      // scrollwheel: false,
      styles: mapStyle,
    };
  };

  function finishLoading(map, maps) {
    if (auth && !auth.passenger) {
      initControls(map);
    }
    loadMap(map, maps);
  }

  return (
    <Container isShare={isShare}>
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => finishLoading(map, maps)}
        bootstrapURLKeys={{ key: 'AIzaSyApYh8zGAIB2GLkjEoAOkI3T3WoSz2jMHY' }}
        defaultCenter={{ lat: -20.361797, lng: -40.660631 }}
        defaultZoom={12}
        options={createMapOptions}
      >
        {children}
      </GoogleMapReact>
    </Container>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.element.isRequired,
  loadMap: PropTypes.func.isRequired,
  isShare: PropTypes.bool,
};

GoogleMap.defaultProps = {
  isShare: false,
};
