import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import SimpleButton from '~/components/Buttons/SimpleButton';
import PasswordInput from '~/components/Inputs/PasswordInput';
import api from '~/services/api';
import { Creators as UserActions } from '~/store/modules/user/duck';

import { Container } from './styles';

export default function ModalContent() {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.user.visible);
  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: '',
    },
    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (!values.old_password) errors.old_password = message;
      if (!values.new_password) errors.new_password = message;
      if (!values.confirm_new_password) errors.confirm_new_password = message;
      if (
        values.new_password &&
        values.confirm_new_password &&
        values.new_password !== values.confirm_new_password
      ) {
        errors.confirm_new_password = 'Repita sua nova senha';
      }
      return errors;
    },
    onSubmit: async (data) => {
      try {
        await api.put(`/changepassword`, data);
        await toast.success('Senha atualizada!');
        await formik.resetForm();

        dispatch(UserActions.setVisible());
      } catch (error) {
        toast.error('Erro ao atualizar a senha!');
      }
    },
  });

  function handleClose() {
    dispatch(UserActions.setVisible());
  }

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="300px"
      height="266px"
    >
      <Container onSubmit={formik.handleSubmit}>
        <header>Mudar a senha</header>
        <div>
          <PasswordInput
            color="#333"
            placeholder="Senha antiga"
            name="old_password"
            id="old_password"
            onChange={formik.handleChange}
            value={formik.values.old_password}
            error={formik.touched.old_password && formik.errors.old_password}
          />
          <PasswordInput
            color="#333"
            placeholder="Nova senha"
            name="new_password"
            id="new_password"
            onChange={formik.handleChange}
            value={formik.values.new_password}
            error={formik.touched.new_password && formik.errors.new_password}
          />
          <PasswordInput
            color="#333"
            placeholder="Confirme a senha"
            name="confirm_new_password"
            id="confirm_new_password"
            onChange={formik.handleChange}
            error={
              formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password
            }
            value={formik.values.confirm_new_password}
          />
        </div>
        <footer>
          <SimpleButton
            text="cancelar"
            bg="#E33939"
            color="#fff"
            onClick={() => handleClose()}
          />
          <SimpleButton
            text="Mudar"
            bg="#03669f"
            color="#fff"
            type="submmit"
            loading={false}
          />
        </footer>
      </Container>
    </Modal>
  );
}
