import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  groupRequest: [],
  groupSuccess: ['data'],
  groupFailure: [],
  groupUpdate: ['group', 'id'],
  groupCreate: ['group'],
  setIsNewGroup: ['bool'],
});

const INITIAL_STATE = {
  groups: [],
  loading: false,
  isNewGroup: false,
};

const groupRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const groupSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  groups: action.payload.data,
  isNewGroup: false,
  loading: false,
});

const groupFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const groupUpdate = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const groupCreate = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};
const setIsNewGroup = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isNewGroup: action.bool,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.GROUP_REQUEST]: groupRequest,
  [Types.GROUP_SUCCESS]: groupSuccess,
  [Types.GROUP_FAILURE]: groupFailure,
  [Types.GROUP_UPDATE]: groupUpdate,
  [Types.GROUP_CREATE]: groupCreate,
  [Types.SET_IS_NEW_GROUP]: setIsNewGroup,
});
