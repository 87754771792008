import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const MonitoringContext = createContext({});

export function useMonitoring() {
  const context = useContext(MonitoringContext);
  if (!context)
    throw new Error('useMonitoring must be used within an TesteProvider');
  return context;
}

export const MonitoringProvider = ({ children }) => {
  const [message, setMessage] = useState('teste');

  return (
    <MonitoringContext.Provider value={{ message, setMessage }}>
      {children}
    </MonitoringContext.Provider>
  );
};

MonitoringProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
