// import { Alert } from 'react-native';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* groups() {
  try {
    const response = yield call(api.get, 'groups');

    const { data } = response;

    yield put({
      type: Types.GROUP_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: Types.GROUP_FAILURE,
    });
  }
}

export function* update(payload) {
  try {
    const { group, id } = payload;
    const { data } = yield call(api.put, `/groups${id}`, group);

    toast.success('Grupo Atualizado com sucesso!');

    yield put({
      type: Types.GROUP_REQUEST,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: Types.GROUP_FAILURE,
    });
  }
}
export function* create(payload) {
  try {
    const { group } = payload;
    const { data } = yield call(api.post, `/groups`, group);

    toast.success('Grupo Criado com sucesso!');

    yield put({
      type: Types.GROUP_REQUEST,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: Types.GROUP_FAILURE,
    });
  }
}

export default all([
  takeLatest(Types.GROUP_REQUEST, groups),
  takeLatest(Types.GROUP_UPDATE, update),
  takeLatest(Types.GROUP_CREATE, create),
]);
