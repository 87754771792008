import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import block from './block/sagas';
import buffer from './buffer/sagas';
import command from './command/sagas';
import group from './groups/sagas';
import notification from './notification/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  // yield take(REHYDRATE);
  return yield all([block, auth, buffer, user, command, notification, group]);
}
