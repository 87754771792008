import arraySort from 'array-sort';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* notifications({ query }) {
  try {
    const response = yield call(api.get, `/alerts${query || ''}`);

    const { data } = response;
    const newData = arraySort(data, 'status');

    yield put({
      type: Types.NOTIFICATION_SUCCESS,
      payload: { data: newData },
    });
  } catch (error) {
    yield put({
      type: Types.NOTIFICATION_FAILURE,
    });
  }
}

export default all([takeLatest(Types.NOTIFICATION_REQUEST, notifications)]);
