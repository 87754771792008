import styled from 'styled-components';

export const Box = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  margin: 5px 10px 0;
  padding: 5px;
  border-radius: 5px;
  > header {
    font-size: 15px;
    font-weight: 600;
  }

  > footer {
    font-size: 12px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-gap: 10px;
  height: calc(100% - 60px);
`;

export const Body = styled.div``;

export const Header = styled.div`
  margin: 10px;
  height: 40px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  > input {
    border: none;
    background: transparent;
    height: 40px;
    font-size: 15px;
    /* width: 100%; */
    ::placeholder {
      font-size: 15px;
    }
  }

  > button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    background: rgb(74, 105, 113);
  }
`;
