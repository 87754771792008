import axios from 'axios';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdWatchLater, MdDirections, MdLocalPhone } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import notAvatar from '~/assets/avatar.png';
import battery from '~/assets/detailIcons/battery.svg';
import car from '~/assets/detailIcons/car.svg';
import directions from '~/assets/detailIcons/directions.svg';
import odometer from '~/assets/detailIcons/odometer.svg';
import location from '~/assets/detailIcons/position.svg';
import Logo from '~/assets/logoFull.svg';
import markerAlert from '~/assets/marker/markerAlert.svg';
import markerRun from '~/assets/marker/markerRun.svg';
import markerStop from '~/assets/marker/markerStop.svg';
import GoogleMap from '~/components/GoogleMap';
import { Marker } from '~/pages/Monitoring/styles';

import { Container, Content, Item, Action, GeoContainer } from './styles';

export default function DetailCar() {
  const dispatch = useDispatch();
  const { token } = useParams();

  const [address, setAddress] = useState('clique para ver o endereço...');
  const [marker, setMarker] = useState(markerStop);
  const [alert, setAlert] = useState(null);
  const [angle, setAngle] = useState(null);
  const [tracker, setTracker] = useState({});
  const [isBlock, setIsBlock] = useState(false);
  const [dateFormated, setDateFormated] = useState('');
  const [mapConst, setMapConst] = useState(null);
  const [mapsConst, setMapsConst] = useState(null);
  const [hasCommand, setHasCommand] = useState(false);
  // const [address, setAddress] = useState('Carregando ...');

  function getDirections(angleTracker) {
    let nameAngle;
    if (angleTracker > 337.5 && angleTracker <= 22.5) nameAngle = 'Norte';
    if (angleTracker <= 22.5) nameAngle = 'Norte';
    if (angleTracker > 22.5 && angleTracker <= 67.5) nameAngle = 'Nordeste';
    if (angleTracker > 67.5 && angleTracker <= 112.5) nameAngle = 'Leste ';
    if (angleTracker > 112.5 && angleTracker <= 157.5) nameAngle = 'Sudeste';
    if (angleTracker > 157.5 && angleTracker <= 202.5) nameAngle = 'Sul';
    if (angleTracker > 202.5 && angleTracker <= 247.5) nameAngle = 'Sudoeste';
    if (angleTracker > 247.5 && angleTracker <= 292.5) nameAngle = 'Oeste';
    if (angleTracker > 292.5 && angleTracker <= 337.5) nameAngle = 'Noroeste';
    return setAngle(nameAngle);
  }

  async function getAddress(lat, lng) {
    if (lat && lng) {
      try {
        const { data } = await axios.get(
          `https://location.georastreamento.com.br/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=16`
        );
        const { road, city_district, town, city, state } = data.address;
        setAddress(
          `${road || city_district}${
            city || town ? `, ${city || town}` : ''
          } - ${state}`
        );
      } catch (error) {
        setAddress('...');
      }
    }
  }
  async function getBuffer(token) {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/bufferShared`,
        {
          headers: { Authorization: token },
        }
      );

      !_.isEmpty(data.buffer) && setTracker(data.buffer);
    } catch (error) {}
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        getBuffer(token);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (token) {
      getBuffer(token);
    }
  }, [token]);

  useEffect(() => {
    if (!_.isEmpty(tracker)) {
      if (!_.isEmpty(mapConst)) {
        mapConst.setCenter(
          new window.google.maps.LatLng(tracker.lat, tracker.lng)
        );
        mapConst.setZoom(16);
      }
      getAddress(tracker.lat, tracker.lng);
      const date = parseISO(tracker.date);
      const formated = format(date, 'dd/MM/yyyy HH:mm');
      setDateFormated(formated);
      if (tracker.status === 'DANGER') {
        setMarker(markerAlert);
      } else if (tracker.ignition) {
        setMarker(markerRun);
      } else {
        setMarker(markerStop);
      }

      if (tracker.status === 'DISCONNECTED') {
        setAlert('DISCONNECTED');
      } else if (tracker.alert_on) {
        setAlert('ALERT');
      } else {
        setAlert(null);
      }
      getDirections(tracker.angle);
      // getAddress(tracker.lat, tracker.lng);
      if (tracker.output1 || tracker.output2 || tracker.output3) {
        setIsBlock(true);
      } else {
        setIsBlock(false);
      }
    }
  }, [tracker]);

  function mapLoad(map, maps) {
    // const bounds = getMapBounds(map, maps, tracker);
    // map.fitBounds(bounds);

    map.setCenter(new window.google.maps.LatLng(tracker.lat, tracker.lng));
    map.setZoom(16);
    setMapConst(map);
    setMapsConst(maps);
  }

  function directionsRedirect(lat, lng) {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
    );
  }

  return (
    <>
      <GeoContainer
        href="http://www.educartic.com.br/georastreamento/"
        target="_blank"
      >
        <img src={Logo} alt="logoGEo" />
        <div>
          <MdLocalPhone size={20} />
          <span>(27) 3268-3953</span>
        </div>
      </GeoContainer>
      <Container>
        <GoogleMap isShare loadMap={(map, maps) => mapLoad(map, maps)}>
          {!_.isEmpty(tracker) && (
            <Marker
              key={tracker.tracker_id}
              alert={alert}
              rotate={tracker.angle}
              lat={tracker.lat}
              lng={tracker.lng}
            >
              <div>{tracker.label}</div>
              <img src={marker} alt="carros" />
            </Marker>
          )}
        </GoogleMap>
        <Content>
          <header>
            <img
              src={
                tracker.driver_image
                  ? `${process.env.REACT_APP_SERVER_FILE}/${tracker.driver_image}`
                  : notAvatar
              }
              alt="Motorista"
            />
            <div>
              <strong>
                {tracker.driver ? tracker.driver : 'Sem motorista vinculado'}
              </strong>
              <small>{tracker.label} </small>
            </div>
            <span>
              <strong>
                {tracker.rpm && tracker.rpm < 9999 ? (
                  <div>
                    <strong>{tracker.speed} Km/h</strong>
                    <small>{tracker.rpm} RPM</small>
                  </div>
                ) : (
                  tracker.speed || '-'
                )}
              </strong>
              <small>{!(tracker.rpm && tracker.rpm < 9999) && 'Km/h'}</small>
            </span>
          </header>
          <aside>
            <Item>
              <div>
                <img src={car} alt="Carro" />
                <small>{`${tracker.brand} ${tracker.model}`}</small>
              </div>
              <div>
                <img src={directions} alt="Velocidade" />
                <small>{`${angle} - (${tracker.lat}, ${tracker.lng})`}</small>
              </div>
              <div>
                <img src={battery} alt="Voltagem" />
                <small>{`${tracker.power_voltage} V`}</small>
              </div>
              <div>
                <img src={odometer} alt="Odometro" />
                <small>
                  {tracker.hour_meter
                    ? `${(tracker.h_meter / 60).toFixed(0)} H`
                    : `${(tracker.odometer / 1000).toFixed(0)} Km`}
                </small>
              </div>
              <div>
                <MdWatchLater size={20} color="#777" />
                <small>{dateFormated}</small>
              </div>
              <div>
                <img src={location} alt="Logradrouro" />
                <small>{address}</small>
              </div>
            </Item>
            <Action>
              <button
                type="button"
                onClick={() => directionsRedirect(tracker.lat, tracker.lng)}
              >
                <MdDirections size={30} color="#333" />
              </button>
            </Action>
          </aside>
        </Content>
      </Container>
    </>
  );
}
