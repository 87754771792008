import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  userRequest: [],
  userSuccess: ['data'],
  userFailure: [],
  userUpdate: ['user', 'id'],
  setVisible: [],
});

const INITIAL_STATE = {
  user: null,
  loading: false,
  visible: false,
};

const userRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const userSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  user: action.payload.data,
  loading: false,
});

const userFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const userUpdate = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setVisible = (state = INITIAL_STATE) => ({
  ...state,
  visible: !state.visible,
});

export default createReducer(INITIAL_STATE, {
  [Types.USER_REQUEST]: userRequest,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_FAILURE]: userFailure,
  [Types.USER_UPDATE]: userUpdate,
  [Types.SET_VISIBLE]: setVisible,
});
