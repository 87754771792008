import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  bufferRequest: ['id'],
  bufferSuccess: ['data'],
  bufferFailure: [],
  bufferUpdate: ['data'],
  setLastId: ['id'],
  filterStatus: ['status'],
});

const INITIAL_STATE = {
  trackers: [],
  trackersImutable: [],
  loading: false,
  lastId: '',
  filterActive: 'ALL',
};

const bufferRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const bufferSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  trackers:
    (window.localStorage.filterActive || 'ALL') === 'ALL'
      ? action.payload.data
      : action.payload.data.filter(
          (item) => state.filterActive === item.status
        ),
  trackersImutable: action.payload.data,
  loading: false,
});

const bufferFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const bufferUpdate = (state = INITIAL_STATE, action) => ({
  ...state,
  trackers: state.trackers.map((tracker) => {
    if (tracker.tracker_id === Number(action.data.tracker_id)) {
      return action.data;
    }
    return tracker;
  }),
});

const setLastId = (state = INITIAL_STATE, action) => ({
  ...state,
  lastId: action.id,
});

const filterStatus = (state = INITIAL_STATE, action) => ({
  ...state,
  trackers:
    window.localStorage.filterActive === 'ALL'
      ? state.trackersImutable
      : state.trackersImutable.filter(
          (tracker) => tracker.status === window.localStorage.filterActive
        ),
  filterActive: window.localStorage.filterActive,
});

export default createReducer(INITIAL_STATE, {
  [Types.BUFFER_REQUEST]: bufferRequest,
  [Types.BUFFER_SUCCESS]: bufferSuccess,
  [Types.BUFFER_FAILURE]: bufferFailure,
  [Types.BUFFER_UPDATE]: bufferUpdate,
  [Types.SET_LAST_ID]: setLastId,
  [Types.FILTER_STATUS]: filterStatus,
});
