import styled from 'styled-components';

export const Container = styled.div`
  grid-area: car;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  padding: 10px;
  > div {
    > b {
      color: #245378;
      font-size: 30px;
    }
    > span {
      font-size: 15px;
      font-weight: 200;
    }
  }
`;
