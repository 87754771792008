import { useFormik } from 'formik';
import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '~/components/Buttons/SimpleButton';
import PasswordInput from '~/components/Inputs/PasswordInput';
import { Creators as BlockActions } from '~/store/modules/block/duck';

import { ContainerModal } from './styles';

function Form({ params, cars, id }) {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.block.modal);
  const loading = useSelector((state) => state.block.loading);
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (data) => {
      if (id) {
        dispatch(BlockActions.updateBlock({ ...params, ...data, id }));
      } else {
        dispatch(BlockActions.createBlock({ ...params, ...data }));
      }
    },
  });

  function handleClose() {
    dispatch(BlockActions.setModal());
  }

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="350px"
      height="266px"
    >
      <ContainerModal onSubmit={formik.handleSubmit}>
        <header>Confirmação de bloqueio agendado</header>
        <div>
          <strong>
            Tem certeza que deseja bloquear o(s) carro(s):{' '}
            {cars.filter((car) => car.checked).map((car) => `${car.plate} `)}
          </strong>
          <PasswordInput
            color="#666"
            background="#fff"
            placeholder="Confirme sua senha"
            name="password"
            id="password"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </div>
        <footer>
          <SimpleButton
            text="cancelar"
            bg="#E33939"
            color="#fff"
            onClick={() => handleClose()}
          />
          <SimpleButton
            text="Enviar"
            bg="#03669f"
            color="#fff"
            type="submmit"
            loading={loading}
          />
        </footer>
      </ContainerModal>
    </Modal>
  );
}

export default Form;
