import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid:
    'header' 50px
    'container' 1fr
    / 1fr;
  height: calc(100% - 50px);
`;

export const Header = styled.div`
  grid-area: header;
  background-color: #4a6971;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  grid-area: container;
  background-color: #f5f5f5;
  overflow-y: scroll;
  min-height: calc(100% - 50px);
`;

export const BackContainer = styled.button`
  position: absolute;
  background: none;
  border: 0;
  left: 0;
  display: flex;
  align-items: center;
`;
