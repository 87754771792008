import _ from 'lodash';
import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import history from '~/services/history';
import { Creators as BlockActions } from '~/store/modules/block/duck';

import { Container, Box, Header, Body } from './styles';

function List() {
  const dispatch = useDispatch();
  const schedulesImutable = useSelector((state) => state.block.schedules || []);
  const [schedules, setSchedules] = useState([]);
  const loading = useSelector((state) => state.block.loading);
  useEffect(() => {
    dispatch(BlockActions.fetchBlock());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(schedulesImutable)) {
      setSchedules([...schedulesImutable]);
    }
  }, [schedulesImutable]);

  function searchSchedule(value) {
    const filtered = matchSorter(schedulesImutable, value, { keys: ['name'] });
    setSchedules([...filtered]);
  }

  return (
    <Container>
      <Header>
        <input
          placeholder="Pesquisar"
          onChange={(e) => searchSchedule(e.target.value || '')}
        />
        <button type="button" onClick={() => history.push('/block/form')}>
          <MdAdd size={20} color="#F9FCFE" />
        </button>
      </Header>
      <Body>
        {loading
          ? 'loading...'
          : schedules.map((schedule) => (
              <Box onClick={() => history.push(`/block/form/${schedule.id}`)}>
                <header>{schedule.name}</header>
                <footer>
                  <div>
                    <b>Data de bloqueio:</b>
                    {moment(schedule.date_start).format('DD/MM/YYYY HH:mm')}
                  </div>
                  <div>
                    <b>Data de desbloqueio:</b>
                    {moment(schedule.date_end).format('DD/MM/YYYY HH:mm')}
                  </div>
                  <div>
                    <b>Quantidade de veículo(s):</b>
                    {schedule.id_trackers.length}
                  </div>
                </footer>
              </Box>
            ))}
      </Body>
    </Container>
  );
}

export default List;
