import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setModal: [],
  fetchBlock: ['id'],
  createBlock: ['data'],
  updateBlock: ['data'],
  deleteBlock: ['id'],
  successBlock: ['data'],
  failureBlock: [],
});

const INITIAL_STATE = {
  modal: false,
  loading: false,
  schedules: [],
};

const fetchBlock = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const createBlock = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const updateBlock = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const deleteBlock = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const successBlock = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  modal: false,
  schedules: action.data,
});

const failureBlock = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const setModal = (state = INITIAL_STATE) => ({
  ...state,
  modal: !state.modal,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_MODAL]: setModal,
  [Types.FETCH_BLOCK]: fetchBlock,
  [Types.SUCCESS_BLOCK]: successBlock,
  [Types.FAILURE_BLOCK]: failureBlock,
  [Types.CREATE_BLOCK]: createBlock,
  [Types.UPDATE_BLOCK]: updateBlock,
  [Types.DELETE_BLOCK]: deleteBlock,
});
