import styled from 'styled-components';

export const Container = styled.form`
  height: 100%;

  display: grid;
  grid-template-rows: 40px 1fr 50px;
  grid-gap: 5px;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
  }

  > div {
    background: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    strong {
      color: #7f8b97;
      font-size: 15px;
    }

    small {
      color: #666;
      display: flex;
      align-items: flex-end;

      font-size: 14px;
    }
  }

  footer {
    margin: 0 10px 5px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
`;
