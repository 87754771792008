import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimpleButton from '~/components/Buttons/SimpleButton';
import MaskedInput from '~/components/Inputs/MaskedInput';
import SelectTracker from '~/components/SelectTracker';
import { Creators as NotificationActions } from '~/store/modules/notification/duck';
import { mountParams } from '~/util/Mountparams';

import { DateStyle } from '../../Daily/styles';
import { FilterContainer, HeaderFilter, Select } from '../styles';

function Notification() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.notification.loading);

  const formik = useFormik({
    initialValues: {
      initialDate: moment().subtract(1, 'week').toDate(),
      finalDate: moment().toDate(),
      tracker_id: '',
      checked: false,
    },
    onSubmit: async (data) => {
      const params = { checked: data.checked };
      if (data.initialDate !== '')
        params.initialDate = moment
          .utc(data.initialDate)
          .format('YYYY-MM-DD HH:mm');
      if (data.finalDate !== '')
        params.finalDate = moment
          .utc(data.finalDate)
          .format('YYYY-MM-DD HH:mm');
      if (data.tracker_id !== '') params.tracker_id = data.tracker_id;
      const query = mountParams(params);
      await dispatch(NotificationActions.notificationRequest(query));
    },
  });

  useEffect(() => {
    const params = { checked: false };

    params.initialDate = moment
      .utc()
      .subtract(1, 'week')
      .format('YYYY-MM-DD HH:mm')
      .valueOf();

    params.finalDate = moment.utc().format('YYYY-MM-DD HH:mm').valueOf();
    const query = mountParams(params);
    dispatch(NotificationActions.notificationRequest(query));
  }, []);

  return (
    <FilterContainer onSubmit={formik.handleSubmit}>
      <HeaderFilter>Filtros</HeaderFilter>
      <SelectTracker
        changeTracker={(tracker) =>
          formik.setFieldValue('tracker_id', tracker.id)
        }
      />
      <Select
        onChange={(value) =>
          formik.setFieldValue('checked', value.target.value)
        }
        value={formik.values.checked}
      >
        <option value="true">Sim</option>
        <option value="false">Não</option>
      </Select>
      <DateStyle
        showTimeInput
        placeholderText="Data de inicio"
        dateFormat="dd/MM/yyyy HH:mm"
        selected={formik.values.initialDate}
        onChange={(value) =>
          formik.setFieldValue('initialDate', moment(value).toDate())
        }
        locale={pt}
        customInput={<MaskedInput mask="99/99/9999 99:99" />}
      />
      <DateStyle
        showTimeInput
        placeholderText="Data de fim"
        dateFormat="dd/MM/yyyy HH:mm"
        selected={formik.values.finalDate}
        onChange={(value) =>
          formik.setFieldValue('finalDate', moment(value).toDate())
        }
        locale={pt}
        customInput={<MaskedInput mask="99/99/9999 99:99" />}
      />
      <SimpleButton
        area="button"
        type="submit"
        text="Pesquisar"
        loading={loading}
        bg="#245378"
        color="#fff"
      />
    </FilterContainer>
  );
}

export default Notification;
