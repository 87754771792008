// import { Alert } from 'react-native';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';

import { Types } from './duck';

export function* fetchBlock(payload) {
  try {
    let response;
    if (payload.id) {
      response = yield call(api.get, `schedules/${payload.id}`);
    } else {
      response = yield call(api.get, 'schedules');
    }
    yield put({
      type: Types.SUCCESS_BLOCK,
      data: response.data,
    });
  } catch (error) {
    toast.error('Erro ao agendar bloqueio!');
    yield put({
      type: Types.FAILURE_BLOCK,
    });
  }
}

export function* createBlock(payload) {
  try {
    yield call(api.post, 'schedules', payload.data);
    toast.success('Agendamento criado com sucesso!');
    yield history.push('/block');

    yield put({
      type: Types.FETCH_BLOCK,
    });
  } catch (error) {
    toast.error('Erro ao agendar bloqueio!');
    yield put({
      type: Types.FAILURE_BLOCK,
    });
  }
}
export function* updateBlock(payload) {
  try {
    yield call(api.put, `schedules/${payload.data.id}`, payload.data);
    toast.success('Agendamento atualizado com sucesso!');
    yield history.push('/block');

    yield put({
      type: Types.FETCH_BLOCK,
    });
  } catch (error) {
    toast.error('Erro ao atualizar o agendamento!');
    yield put({
      type: Types.FAILURE_BLOCK,
    });
  }
}
export function* deleteBlock(payload) {
  try {
    yield call(api.delete, `schedules/${payload.id}`);
    toast.success('Agendamento removido com sucesso!');
    yield history.push('/block');

    yield put({
      type: Types.FETCH_BLOCK,
    });
  } catch (error) {
    toast.error('Erro ao remover o agendamento!');
    yield put({
      type: Types.FAILURE_BLOCK,
    });
  }
}

export default all([
  takeLatest(Types.FETCH_BLOCK, fetchBlock),
  takeLatest(Types.CREATE_BLOCK, createBlock),
  takeLatest(Types.UPDATE_BLOCK, updateBlock),
  takeLatest(Types.DELETE_BLOCK, deleteBlock),
]);
