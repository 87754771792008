// import { Alert } from 'react-native';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* trackers(payload) {
  try {
    const id = window.localStorage.clientId;
    const { groupId } = window.localStorage;

    let response;
    if (payload.id) {
      response = yield call(api.get, `/buffer/${payload.id}`);
    } else if (groupId) {
      response = yield call(api.get, `/buffer/${groupId}`);
    } else {
      response = yield call(api.get, `/buffer${id ? `?client_id=${id}` : ''}`);
    }

    const { data } = response;
    yield put({
      type: Types.BUFFER_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    // Alert.alert(
    //   'Falha na conexão',
    //   'Confira a sua conexão e reinice o aplicativo'
    // );

    yield put({
      type: Types.BUFFER_FAILURE,
    });
  }
}

export default all([takeLatest(Types.BUFFER_REQUEST, trackers)]);
