import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 10px;
  overflow: scroll;
`;

export const GroupName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-size: 12px;
`;
