import styled from 'styled-components';

export const Container = styled.div`
  background: #245378;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  header {
    display: flex;
    align-items: center;
    width: 125px;
    justify-content: space-around;

    > img {
      width: 30px;
      height: 30px;
    }
    > svg {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      padding-left: 5px;
    }
  }

  aside {
    display: flex;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      text-align: right;
      padding: 0 5px;
      max-width: 200px;

      > strong {
        font-size: 13px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    a {
      display: flex;
      align-items: center;
      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.6);
      }
    }
  }
`;

export const ContainerGroup = styled.div``;

export const Form = styled.form`
  display: grid;
  grid-template-rows: 50px 300px 50px;
  height: 400px;
  overflow: scroll;
  > header {
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #4a6971;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    > strong {
      font-size: 20px;
      color: #4a6971;
    }
  }
  > footer {
    display: grid;
    grid-template-columns: ${(props) => props.isNew && '1fr 1fr'};
    grid-gap: 5px;
    margin: 0 10px;
  }
  > div {
    margin: 10px 10px;
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ClientContainer = styled.div`
  display: grid;
  grid-template-rows: 50px 350px;
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #4a6971;
    font-weight: 700;
  }
  > div {
    height: 350px;
    overflow: scroll;
    > button {
      width: 100%;
      height: 40px;
      border: none;
    }
  }
`;

export const ButtonStyled = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  background: ${(props) => props.selected && 'rgba(0,0,0,0.2)'};
`;
export const HeaderTextStyled = styled.span`
  padding-top: 5px;
  font-size: 12px;
  color: white;
`;
