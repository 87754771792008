import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '~/components/Buttons/SimpleButton';
import PasswordInput from '~/components/Inputs/PasswordInput';
import api from '~/services/api';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import { Container, RadioContainer } from './styles';

export default function ModalContent({ tracker }) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const visible = useSelector((state) => state.command.modalShare);
  const loading = useSelector((state) => state.command.loading);
  const { label, tracker_id } = tracker;
  const formik = useFormik({
    initialValues: {
      hours: 1,
    },
    onSubmit: async (values) => {
      const { data } = await api.post('/share', {
        tracker_id,
        hours: parseInt(values.hours),
      });
      const shareOpts = {
        title: 'GEORASTREAMENTO',
        text: `Compartilhamento do carro ${tracker.plate} esse link expira em ${values.hours} horas.`,
        url: data.url,
      };
      if (navigator.share) {
        await navigator
          .share(shareOpts)
          .then(() => {
            console.log('Thanks for sharing!');
          })
          .catch(console.error);
      } else {
        alert('este serviço não está disponivel');
        // console.log('este serviço não está disponivel');
      }
    },
  });
  async function getUrl() {
    const { data } = await api.post('/share', {
      tracker_id,
      hours: parseInt(formik.values.hours),
    });
    setUrl(data.url);
  }
  function shareButton() {
    if (navigator.share) {
      navigator
        .share({
          title: 'GEORASTREAMENTO',
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.log({ title: 'GEORASTREAMENTO', url });
      // shareDialog.classList.add('is-open');
    }
  }

  useEffect(() => {
    formik.setValues({ ...formik.values, tracker_id, password: '' });
  }, [tracker]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [formik.values.hours]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [tracker_id]);

  function handleClose() {
    dispatch(CommandActions.setModalShare());
  }

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="500px"
      height="200px"
    >
      <Container onSubmit={formik.handleSubmit}>
        <header>Compartilhamento de carro</header>
        <div>
          <strong>Tempo de compartilhamento:</strong>
          <RadioContainer>
            <div>
              <input
                id="1"
                type="radio"
                value={1}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 1}
              />
              <label htmlFor="1">1 Hora</label>
            </div>
            <div>
              <input
                id="3"
                type="radio"
                value={3}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 3}
              />
              <label htmlFor="3">3 Horas</label>
            </div>
            <div>
              <input
                id="6"
                type="radio"
                value={6}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 6}
              />
              <label htmlFor="6">6 Horas</label>
            </div>
            <div>
              <input
                id="12"
                type="radio"
                value={12}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 12}
              />
              <label htmlFor="12">12 Horas</label>
            </div>
            <div>
              <input
                id="24"
                type="radio"
                value={24}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 24}
              />
              <label htmlFor="24">24 Horas</label>
            </div>
          </RadioContainer>
        </div>
        <footer>
          <SimpleButton
            text="cancelar"
            bg="#E33939"
            color="#fff"
            onClick={() => handleClose()}
          />
          <SimpleButton
            text="Compartilhar"
            bg="#03669f"
            color="#fff"
            type="button"
            onClick={() => shareButton()}
            loading={loading}
          />
          {/* <SimpleButton
            text="Teste"
            bg="#03669f"
            color="#fff"
            type="button"
            loading={loading}
            onClick={() => shareButton()} */}
          />
        </footer>
      </Container>
    </Modal>
  );
}

ModalContent.propTypes = {
  tracker: PropTypes.objectOf.isRequired,
  isBlock: PropTypes.bool,
};

ModalContent.defaultProps = {
  isBlock: false,
};
