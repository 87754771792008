import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import SimpleButton from '~/components/Buttons/SimpleButton';
import GoogleMaps from '~/components/GoogleMap';
import MaskedInput from '~/components/Inputs/MaskedInput';
import SelectTracker from '~/components/SelectTracker';
import api from '~/services/api';

import markerFinish from '../../assets/markerFinish.svg';
import markerStart from '../../assets/markerStart.svg';
import Detail from './detail';
import { Container, DateStyle, Marker, DetailItem, GriStatics } from './styles';

export default function Daily() {
  const [geoJson, setGeoJson] = useState({});
  const [loading, setLoading] = useState(false);
  const [mapConst, setMapConst] = useState(null);
  const [mapsConst, setMapsConst] = useState(null);
  const [statics, setStatics] = useState({});
  const [journey, setJourney] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [log, setLog] = useState([]);

  const formik = useFormik({
    initialValues: {
      tracker_id: '',
      start: new Date(),
      end: new Date(),
    },
    onSubmit: async (data) => {
      const query = `?id=${data.tracker_id}&start=${moment(data.start).format(
        'YYYY-MM-DDTHH:mm:ssZ'
      )}&end=${moment(data.end).format('YYYY-MM-DDTHH:mm:ssZ')}`;
      try {
        await setLoading(true);
        const responseDaily = await api.get(`/chart/daily${query}`);
        const responseStatics = await api.get(`/statistics${query}`);
        const responseJourney = await api.get(`/dailyJourney${query}`);
        const reponseLog = await api.get(`/logbook${query}`);
        await setGeoJson(responseDaily.data[0]);
        await setStatics(responseStatics.data);
        await setJourney(responseJourney.data);
        await setLog(reponseLog.data);
        await setLoading(false);
      } catch (error) {
        await setStatics({});
        await setJourney([]);
        await setLog([]);
        await setGeoJson({});
        await setLoading(false);
        await toast.warn(
          'Houve um erro, tente novamente mais tarde ou contate o suporte!'
        );
      }
    },
  });
  useEffect(() => {
    if (!_.isEmpty(geoJson) && geoJson && geoJson.st_asgeojson) {
      const coordinates = JSON.parse(geoJson.st_asgeojson);
      const geojson = {
        type: 'Feature',
        geometry: coordinates,
      };
      mapConst.data.addGeoJson(geojson);
      mapConst.data.setStyle({
        strokeColor: '#3AAAAA',
        strokeOpacity: 0.8,
        strokeWeight: 3,
      });
      setMarkers([
        {
          lat: coordinates.coordinates[0][1],
          lng: coordinates.coordinates[0][0],
        },
        {
          lat: coordinates.coordinates[coordinates.coordinates.length - 1][1],
          lng: coordinates.coordinates[coordinates.coordinates.length - 1][0],
        },
      ]);

      const bounds = new window.google.maps.LatLngBounds();
      coordinates.coordinates.map((item) => {
        bounds.extend(new window.google.maps.LatLng(item[1], item[0]));
      });
      mapConst.fitBounds(bounds);
      // console.log(mapConst.getZoom());
      mapConst.setZoom(mapConst.getZoom() - 1);
    } else if (!_.isEmpty(geoJson)) {
      toast.warn('Sem dados para exibir!');
      setStatics({});
      setJourney([]);
      setLog([]);
      setMarkers([]);
      setGeoJson({});
      mapConst.data.forEach(function (feature) {
        mapConst.data.remove(feature);
      });
    }
  }, [geoJson]);
  function mapLoad(map, maps) {
    setMapConst(map);
    setMapsConst(maps);
  }
  useEffect(() => {
    // console.log(formik.values.start);
    const m = moment();

    formik.setFieldValue(
      'start',
      m
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toDate()
    );
    formik.setFieldValue(
      'end',
      m
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        })
        .toDate()
    );
  }, []);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <strong>Diário sobre mapa</strong>
        <div>
          <div style={{ gridArea: 'car' }}>
            <SelectTracker
              changeTracker={(tracker) =>
                formik.setFieldValue('tracker_id', tracker.id)
              }
            />
          </div>
          <DateStyle
            showTimeInput
            dateFormat="dd/MM/yyyy HH:mm:ss"
            selected={formik.values.start}
            onChange={(value) =>
              formik.setFieldValue('start', moment(value).toDate())
            }
            locale={pt}
            customInput={<MaskedInput mask="99/99/9999 99:99" />}
          />
          <DateStyle
            showTimeInput
            dateFormat="dd/MM/yyyy HH:mm:ss"
            selected={formik.values.end}
            onChange={(value) =>
              formik.setFieldValue('end', moment(value).toDate())
            }
            locale={pt}
            customInput={<MaskedInput mask="99/99/9999 99:99" />}
          />
          <SimpleButton
            area="button"
            type="submit"
            text="Gerar"
            loading={loading}
            bg="#245378"
            color="#fff"
          />
        </div>
      </form>
      <GoogleMaps loadMap={(map, maps) => mapLoad(map, maps)}>
        {markers.map((marker, index) => (
          <Marker key={index} lat={marker.lat} lng={marker.lng}>
            <img src={index === 0 ? markerStart : markerFinish} />
          </Marker>
        ))}
      </GoogleMaps>
      {!_.isEmpty(statics) && (
        <Detail statics={statics} journeys={journey} log={log} map={mapConst} />
      )}
    </Container>
  );
}
