import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid:
    'avatar info status' 1fr
    / 75px 1fr 70px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
`;

export const AvatarContainer = styled.div`
  grid-area: avatar;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Avatar = styled.img`
  height: 22px;
`;

export const InfoContainer = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Plate = styled.div`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-top: 4px;
`;

export const Driver = styled.div`
  font-size: 13px;
  color: #555;
  margin-top: 4px;
`;

export const Address = styled.p.attrs({
  numberOfLines: 2,
})`
  font-size: 13px;
  line-height: 18px;
  color: #999;
  margin-top: 5px;
`;

export const StatusContainer = styled.div`
  grid-area: status;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Speed = styled.div`
  font-weight: bold;
  color: #333;
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
  }
`;

export const StatusIcon = styled.img`
  height: 25px;
  align-self: center;
  margin-bottom: 10px;
`;

export const Triangle = styled.div`
  width: 18px;
  height: 0;
  border-top: 9px solid ${(props) => (props.color ? props.color : '#666')};
  border-right: 8px solid transparent;
  position: absolute;
`;

export const Block = styled.img`
  position: absolute;
  width: 15px;
  right: 0;
`;
