import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { MdGroup } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import notAvatar from '~/assets/avatar.png';
import logo from '~/assets/logoOnlyNegative.svg';
import Button from '~/components/Buttons/SimpleButton';
import SimpleInput from '~/components/Inputs/SimpleInput';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';
import { Creators as GroupActions } from '~/store/modules/groups/duck';
import { Creators as UserActions } from '~/store/modules/user/duck';

import {
  Container,
  ContainerGroup,
  Form,
  ClientContainer,
  ButtonStyled,
  HeaderTextStyled,
} from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);
  const groups = useSelector((state) => state.groups.groups);
  const trackersImutable = useSelector(
    (state) => state.buffer.trackersImutable
  );
  const [modalVisible, setmodalVisible] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clients, setClients] = useState([]);
  // const [trackersFormated, setTrackersFormated] = useState([]);
  // const [isNewGroup, setIsNewGroup] = useState(false);
  const isNewGroup = useSelector((state) => state.groups.isNewGroup);
  const [clientSelected, setClientSelected] = useState(
    window.localStorage.clientName
  );
  const formik = useFormik({
    initialValues: {
      trackers: [],
      name: '',
    },
    onSubmit: ({ trackers, name }) => {
      const trackers_checked = trackers
        .filter((tracker) => tracker.check)
        .map((tracker) => tracker.value);
      dispatch(
        GroupActions.groupCreate({ name, id_trackers: trackers_checked })
      );
    },
  });
  useEffect(() => {
    dispatch(UserActions.userRequest());
  }, [dispatch]);

  // useEffect(() => {
  //   const socket = socketIOClient(process.env.REACT_APP_SERVER_URL);

  //   socket.on('buffer', (data) => {
  //     const jsonParse = JSON.parse(data);
  //     dispatch(BufferActions.bufferUpdate(jsonParse));
  //     dispatch(BufferActions.setLastId(jsonParse.tracker_id));
  //   });
  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  useEffect(() => {
    async function getMe() {
      try {
        const { data } = await api.get('/me');
        setClientName(data.clientName);
      } catch (error) {
        setClientName('');
      }
    }
    getMe();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(trackersImutable)) {
      const dataFormated = trackersImutable.map(({ label, tracker_id }) => ({
        label,
        value: tracker_id,
        check: false,
      }));
      formik.setFieldValue('trackers', dataFormated);
    }
  }, [trackersImutable]);

  useEffect(() => {
    async function getClients() {
      try {
        const { data } = await api.get('/clients');
        setClients(data);
        if (!clientSelected) {
          const client = data.find((item) => item.name === 'GEORASTREAMENTO');
          window.localStorage.clientId = client.id;
          window.localStorage.clientName = client.name;
          dispatch(BufferActions.bufferRequest());

          setClientSelected(client.name);
        }
      } catch (error) {
        setClients([]);
      }
    }
    if (!auth.passenger && clientName) {
      if (clientName === 'GEORASTREAMENTO') {
        getClients();
      } else {
        dispatch(GroupActions.groupRequest());
      }
    }
  }, [clientName]);

  function showModal() {
    setmodalVisible(!auth.passenger && !modalVisible);
  }

  function clientChange(client) {
    window.localStorage.clientId = client.id;
    window.localStorage.clientName = client.name;
    window.localStorage.filterActive = 'ALL';
    dispatch(BufferActions.bufferRequest());
    setClientSelected(client.name);
    showModal();
  }
  function groupChange(group) {
    if (window.localStorage.groupId == group.id) {
      window.localStorage.groupId = '';
      window.localStorage.groupName = '';
      window.localStorage.filterActive = 'ALL';
      setClientSelected('');
      showModal();
      dispatch(BufferActions.bufferRequest());
    } else {
      window.localStorage.filterActive = 'ALL';
      dispatch(BufferActions.bufferRequest(group.id));
      window.localStorage.groupId = group.id;
      window.localStorage.groupName = group.name;
      setClientSelected(group.name);
      showModal();
    }
  }

  function newGroup() {
    dispatch(GroupActions.setIsNewGroup(true));
  }

  return (
    <>
      <Container>
        <header>
          <img src={logo} alt="georastreamento" />
          <MdGroup
            size={30}
            color="rgba(255, 255, 255, 0.7)"
            onClick={showModal}
          />
          <HeaderTextStyled>v{process.env.REACT_APP_VERSION}</HeaderTextStyled>
        </header>
        <aside>
          <div>
            <strong>{profile && profile.name}</strong>
            <small>
              {clientName === 'GEORASTREAMENTO'
                ? clientSelected
                : `${clientName}${
                    clientSelected !== undefined ? ` - ${clientSelected}` : ''
                  }`}
            </small>
          </div>

          <a onClick={() => history.push('/profile')} href>
            <img
              src={
                profile && profile.image
                  ? `${process.env.REACT_APP_SERVER_FILE}/${profile.image}`
                  : notAvatar
              }
              alt="foto de perfil"
            />
          </a>
        </aside>
      </Container>
      <Modal
        visible={modalVisible}
        onClickAway={() => showModal()}
        effect="fadeInUp"
        width="300px"
        height="400px"
      >
        <ContainerGroup>
          {clientName === 'GEORASTREAMENTO' ? (
            <ClientContainer>
              <header>Clientes</header>
              <div>
                {!_.isEmpty(clients) &&
                  clients.map((client) => (
                    <button type="button" onClick={() => clientChange(client)}>
                      {client.name}
                    </button>
                  ))}
              </div>
            </ClientContainer>
          ) : (
            <Form onSubmit={formik.handleSubmit} isNew={isNewGroup}>
              <header>
                <strong>Grupos</strong>
              </header>
              <div>
                {isNewGroup ? (
                  <div>
                    <SimpleInput
                      type="name"
                      placeholder="Nome do grupo"
                      name="name"
                      id="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {!_.isEmpty(formik.values.trackers) &&
                      formik.values.trackers.map((tracker, index) => (
                        <div>
                          <input
                            type="checkbox"
                            checked={tracker.check}
                            id={tracker.value}
                            onChange={(event) =>
                              formik.setFieldValue(
                                `trackers[${index}].check`,
                                event.target.checked
                              )
                            }
                            value={formik.values.trackers[index].check}
                          />
                          <label htmlFor={tracker.value}>{tracker.label}</label>
                        </div>
                      ))}
                  </div>
                ) : _.isEmpty(groups) ? (
                  <div>Nenhum grupo encontrado</div>
                ) : (
                  <div>
                    {groups.map((group) => (
                      <ButtonStyled
                        selected={group.id == window.localStorage.groupId}
                        type="button"
                        onClick={() => groupChange(group)}
                      >
                        {group.name}
                      </ButtonStyled>
                    ))}
                  </div>
                )}
              </div>
              <footer>
                {isNewGroup ? (
                  <>
                    <Button
                      type="button"
                      onClick={() =>
                        dispatch(GroupActions.setIsNewGroup(false))
                      }
                      text="Cancelar"
                      bg="#ff4e4e"
                      color="#fff"
                    />
                    <Button
                      type="submmit"
                      text="Salvar"
                      color="#fff"
                      bg="#293e52"
                    />
                  </>
                ) : (
                  <Button
                    type="button"
                    onClick={() => newGroup()}
                    bg="#293e52"
                    color="#fff"
                    text="Novo grupo"
                  />
                )}
              </footer>
            </Form>
          )}
        </ContainerGroup>
      </Modal>
    </>
  );
}
