import _ from 'lodash';
import React from 'react';
import { GiSiren } from 'react-icons/gi';
import { MdTimer } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import lock from '~/assets/command/lockBlue.svg';
import unLock from '~/assets/command/unlockBlue.svg';
import horimeter from '~/assets/horimeter.svg';
import odometer from '~/assets/odometer.svg';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import { Container } from './styles';

function Actions() {
  const commandList = useSelector((state) => state.command.commandList);
  const tracker = useSelector((state) => state.command.tracker);
  const dispatch = useDispatch();
  const { output1 = false, output2 = false, output3 = false } = useSelector(
    (state) => state.command.buffer
  );
  function setModal(type) {
    dispatch(CommandActions.setViewModal(type));
  }

  function getStatusBLock() {
    if (!_.isEmpty(tracker) && tracker.device.output1 === 'BLOCK') {
      return output1;
    }
    if (!_.isEmpty(tracker) && tracker.device.output2 === 'BLOCK') {
      return output2;
    }
    if (!_.isEmpty(tracker) && tracker.device.output3 === 'BLOCK') {
      return output3;
    }

    return false;
  }

  return (
    <Container>
      <button
        type="button"
        onClick={() => setModal('block')}
        disabled={
          _.isEmpty(commandList.commands)
            ? true
            : !commandList.commands
                .map((command) => command.type)
                .includes('BLOCK')
        }
      >
        {getStatusBLock() ? (
          <img src={lock} alt="Desbloquear" />
        ) : (
          <img src={unLock} alt="Bloquear" />
        )}
        {/* <span>Bloquear</span> */}
      </button>
      <button type="button" onClick={() => setModal('siren')} disabled>
        <GiSiren size={50} color="#03669F" />
        {/* <span>Sirene</span> */}
      </button>
      <button type="button" onClick={() => setModal('timer')}>
        <MdTimer size={40} color="#03669F" />
        {/* <span>Tempo de transmissão</span> */}
      </button>
      <button
        type="button"
        onClick={() => setModal(tracker.hour_meter ? 'orimeter' : 'odometer')}
      >
        {tracker.hour_meter ? (
          <img src={horimeter} alt="Horimetro" />
        ) : (
          <img src={odometer} alt="odômetro" />
        )}
        {/* <span>Hodometro</span> */}
      </button>
    </Container>
  );
}

export default Actions;
