import PropTypes from 'prop-types';
import React from 'react';

import Logo from '~/assets/negativeLogo.svg';

import { Wrapper, Image } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      <Image src={Logo} alt="logo" />
      {children}
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
