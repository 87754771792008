import styled from 'styled-components';

import MaskedInput from '~/components/Inputs/MaskedInput';

export const Container = styled.form`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-gap: 10px;
  height: calc(100% - 60px);
`;

export const Header = styled.div`
  margin: 10px;
  height: 40px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
`;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  background: ${(props) => props.bg};
  margin-left: 5px;
`;

export const Body = styled.div`
  padding: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 5px;
  height: 100%;
  width: calc(100% - 20px);
  margin-left: 10px;
  overflow: scroll;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelCar = styled.div`
  width: 100%;
  /* margin: 5px; */
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  border-radius: 5px;
  background: ${(props) => props.checked && 'rgba(74,105,113,0.3)'};
  > input {
    display: none;
  }

  > label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 40px 1fr;
  grid-template-areas: 'carHeader carHeader carHeader';
  grid-gap: 5px;
  overflow: scroll;
`;

export const CarHeader = styled.div`
  grid-area: carHeader;
  display: flex;
`;

export const InputPlate = styled(MaskedInput)`
  border: none;
  background: transparent;
  font-size: 12px;
  ::placeholder {
    font-size: 12px;
  }
`;

export const ContainerModal = styled.form`
  height: 100%;

  display: grid;
  grid-template-rows: 40px 1fr 50px;
  grid-gap: 5px;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
  }

  > div {
    background: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    strong {
      color: #7f8b97;
      font-size: 15px;
    }

    small {
      color: #666;
      display: flex;
      align-items: flex-end;

      font-size: 14px;
    }
  }

  footer {
    margin: 0 10px 5px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
`;
