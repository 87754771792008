import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '~/components/Buttons/SimpleButton';
import PasswordInput from '~/components/Inputs/PasswordInput';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import { Container } from './styles';

export default function ModalContent({ tracker, isBlock }) {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.command.tracker);
  const visible = useSelector((state) => state.command.modal);
  const loading = useSelector((state) => state.command.loading);
  const { output1, output2, output3 } = useSelector(
    (state) => state.command.buffer
  );
  const { label, tracker_id } = tracker;

  function getStatusBLock() {
    if (device.output1 === 'BLOCK') {
      return output1;
    }
    if (device.output2 === 'BLOCK') {
      return output2;
    }
    if (device.output3 === 'BLOCK') {
      return output3;
    }
    return false;
  }

  function getPortSuntech() {
    if (device.output1 === 'BLOCK') {
      return 1;
    }
    if (device.output2 === 'BLOCK') {
      return 2;
    }
    return 1;
  }

  const formik = useFormik({
    initialValues: {
      tracker_id,
      type: 'BLOCK',
      password: '',
    },
    onSubmit: (data) => {
      dispatch(
        CommandActions.createCommand({
          cars: [id],
          type: getStatusBLock() ? 'unlock' : 'lock',
          password: data.password,
        })
      );

      // dispatch(CommandActions.sendCommand(data));
    },
  });

  useEffect(() => {
    if (!visible) {
      formik.setValues({ ...formik.values, tracker_id, password: '' });
    }
  }, [tracker]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
  }, [visible]);

  function handleClose() {
    dispatch(CommandActions.setModal());
  }

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="300px"
      height="266px"
    >
      <Container onSubmit={formik.handleSubmit}>
        <header>Confirmação de {isBlock ? 'desbloqueio' : 'bloqueio'}</header>
        <div>
          <strong>
            Tem certeza que deseja {isBlock ? 'desbloquear' : 'bloquear'} o
            carro {label || '-'}?
          </strong>
          <PasswordInput
            color="#666"
            background="#fff"
            placeholder="Confirme sua senha"
            name="password"
            id="password"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </div>
        <footer>
          <SimpleButton
            text="cancelar"
            bg="#E33939"
            color="#fff"
            onClick={() => handleClose()}
          />
          <SimpleButton
            text="Enviar"
            bg="#03669f"
            color="#fff"
            type="submmit"
            loading={loading}
          />
        </footer>
      </Container>
    </Modal>
  );
}

ModalContent.propTypes = {
  tracker: PropTypes.objectOf.isRequired,
  isBlock: PropTypes.bool,
};

ModalContent.defaultProps = {
  isBlock: false,
};
