import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styled from 'styled-components';

const PasswordInput = styled.input.attrs((props) => ({
  type: props.show ? 'input' : 'password',
}))`
  width: 100%;
  height: 40px;
  margin: 5px auto;
  padding: 0 30px 0 10px;
  background: ${(props) =>
    props.background ? props.background : 'rgba(0, 0, 0, 0.01)'};
  color: ${(props) => (props.color ? props.color : '#333')};
  border-radius: 4px;
  border: 1px solid #333;
  font-size: 16px;
  border: 1px solid ${(props) => (props.error ? 'rgba(257,0,0, 1)' : '#ddd')};
  &::placeholder {
    color: ${(props) => (props.color ? props.color : '#333')};
  }
`;

const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  > a {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: -25px;
    width: 25px;
  }
`;

function Input({ disabled, color, ...props }) {
  const [show, setShow] = useState(false);

  return (
    <PasswordContainer>
      <PasswordInput show={show} disabled={disabled} color={color} {...props} />
      <a onClick={() => setShow(!show)} href>
        {show ? (
          <FaEye size={15} color={color} />
        ) : (
          <FaEyeSlash size={15} color={color} />
        )}
      </a>
    </PasswordContainer>
  );
}

Input.propTypes = {
  disabled: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

Input.defaultProps = {
  color: '#fff',
};

export default Input;
