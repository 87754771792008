import PropTypes from 'prop-types';
import React from 'react';

import { MonitoringProvider } from './monitoring';

function AppProvider({ children }) {
  return <MonitoringProvider>{children}</MonitoringProvider>;
}

export default AppProvider;

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
