import DatePicker from 'react-datepicker';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  height: 100%;

  display: grid;

  > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    height: 170px;
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    top: 60px;
    margin-left: 10px;
    width: calc(100% - 20px);
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    padding: 10px;
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 5px;
      grid-template-areas: 'car car' 'date date' 'button button';
    }
  }
`;

export const DateStyle = styled(DatePicker)`
  height: 40px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 4px;
  /* padding-left: 5px; */
  margin-top: 0px;
  color: #999;
  font-size: 14px;
`;

export const Marker = styled.div`
  > img {
    height: 20px;
    width: 20px;
  }
`;

export const Detail = styled(SwipeableViews)`
  position: absolute;
  height: 130px;
  width: calc(100% - 20px);
  bottom: 60px;
  background: #fff;
  z-index: 2;
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailItem = styled.div`
  display: grid;
  height: 125px;
  grid-template-rows: 20px 100px;
  > header {
    font-size: 17px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const GriStatics = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > header {
      > strong {
        font-size: 15px;
      }
      > small {
        font-size: 10px;
      }
    }
    > footer {
      > small {
        font-size: 10px;
      }
    }
  }
`;

export const JourneyContainer = styled.div`
  display: flex;
  height: 100px;
  overflow: auto;
  > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  > aside {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
`;

export const PageCount = styled.footer`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
`;
