import { useFormik } from 'formik';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import MaskedInput from '~/components/Inputs/MaskedInput';
import api from '~/services/api';

import { SubmitButton } from './styles';

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);

  const handleForgot = async (cpf) => {
    setLoading(true);
    try {
      const response = await api.post('auth/forgot', { cpf });
      setEmail(response.data.to);
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      cpf: '',
    },
    onSubmit: ({ cpf }) => {
      handleForgot(cpf);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2>Recuperar senha</h2>
      {email ? (
        <>
          <p>
            Foi enviado um e-mail de recuperação de senha para o endereço:
            <div>
              <center>
                <b>{email}</b>
              </center>
            </div>
          </p>
          <Link to="/">Voltar para login</Link>
        </>
      ) : (
        <>
          <MaskedInput
            id="cpf"
            placeholder="CPF"
            name="cpf"
            type="text"
            required
            onChange={formik.handleChange}
            value={formik.values.cpf}
            mask="999.999.999-99"
            color="#fff"
          />
          {error && <span>CPF não encontrado</span>}
          <SubmitButton loading={loading}>
            {loading ? <FaSpinner color="#fff" size={14} /> : 'Enviar'}
          </SubmitButton>
          <Link to="/">Voltar para login</Link>
        </>
      )}
    </form>
  );
}
