import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  background: #444;
  height: 50px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  margin-bottom: env(safe-area-inset-bottom, 10px);
  width: 100%;
`;

export const ItemTab = styled.div`
  flex: 1;
  color: #f9fcfe;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.active ? 1 : 0.2)};
  transition: 0.2s;
  > img {
    height: 25px;
  }
`;
