import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonStyled from '~/components/Buttons/SimpleButton';
import MaskedInput from '~/components/Inputs/MaskedInput';
import PasswordInput from '~/components/Inputs/PasswordInput';
import { Creators as CommandActions } from '~/store/modules/command/duck';

import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
  Field,
} from './styles';

function Modal() {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.command.tracker);
  const formik = useFormik({
    initialValues: {
      orimeter: '',
      password: '',
    },
    validate: (value) => {
      const err = {};
      if (!value.orimeter) err.orimeter = 'Campo obrigatório!';
      if (!value.password) err.password = 'Campo obrigatório!';
      return err;
    },
    onSubmit: (data) => {
      const orimeterSplit = data.orimeter.split('.');
      const orimeterSum = orimeterSplit[0] * 60 + orimeterSplit[1] * 6;
      let msg;
      if (device.model === 'SUNTECH') {
        msg = `ST300CMD;${device.imei};02;SetHMeter=${orimeterSum}`;
      }
      dispatch(
        CommandActions.createCommand({
          phone: device.phone,
          command: msg,
          value: 'orimeterUpdate',
          tracker_id: id,
          type: 'ORIMETER',
          password: data.password,
        })
      );
    },
  });
  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }

  return (
    <ModalContainer onSubmit={formik.handleSubmit}>
      <Title>Horímetro</Title>
      <Descripition>
        <Field>
          <div>Horímetro</div>
          <MaskedInput
            placeholder="Digite o horímetro"
            mask="99999.9"
            name="orimeter"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.orimeter}
            error={!!(formik.touched.orimeter && formik.errors.orimeter)}
          />
        </Field>

        <PasswordInput
          color="#333"
          required
          placeholder="senha"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={!!(formik.touched.password && formik.errors.password)}
        />
      </Descripition>
      <ButtonsContainer>
        <ButtonStyled
          bg="#ff4e4e"
          color="#eee"
          onClick={() => closeModal()}
          text="Cancelar"
        />
        <ButtonStyled type="subbmit" bg="#01659e" color="#eee" text="Enviar" />
      </ButtonsContainer>
    </ModalContainer>
  );
}

export default Modal;
