import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import markerAlert from '~/assets/marker/markerAlert.svg';
import markerRun from '~/assets/marker/markerRun.svg';
import markerStop from '~/assets/marker/markerStop.svg';
import alertIcon from '~/assets/status/alertIconSemSobra.svg';
import noSignalIcon from '~/assets/status/noSignalIconSemSobra.svg';
import runIcon from '~/assets/status/runIconSemSobra.svg';
import sleepIcon from '~/assets/status/sleepIconSemSobra.svg';
import stopIcon from '~/assets/status/stopIconSemSobra.svg';
import totalIcon from '~/assets/status/totalIconSemSobra.svg';
import GoogleMap from '~/components/GoogleMap';
import history from '~/services/history';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';

import { Marker, StatusContainer, ButtonStatus } from './styles';

export default function Monitoring() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const trackers = useSelector((state) => state.buffer.trackers);
  const trackersImutable = useSelector(
    (state) => state.buffer.trackersImutable
  );
  const filterActive = window.localStorage.filterActive || 'ALL';
  const [mapsConst, setMapsConst] = useState(null);
  const [mapConst, setMapConst] = useState(null);

  async function filterBufferStatus(status) {
    window.localStorage.filterActive = status;
    await dispatch(BufferActions.filterStatus());

    let data;
    if (status === 'ALL') {
      data = trackersImutable;
    } else {
      const filtered = await trackersImutable.filter(
        (item) => item.status === status
      );
      if (filtered.length === 0) {
        data = trackersImutable;
      } else {
        data = filtered;
      }
    }
    const bounds = await getMapBounds(mapConst, mapsConst, data);
    await mapConst.fitBounds(bounds);
  }

  useEffect(() => {
    dispatch(BufferActions.bufferRequest());
  }, []);

  function getMapBounds(map, maps, markers) {
    const bounds = new maps.LatLngBounds();

    markers.forEach((marker) => {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    });
    return bounds;
  }

  useEffect(() => {
    async function getBounds() {
      // const bounds = await getMapBounds(mapConst, mapsConst, trackersImutable);
      // await mapConst.fitBounds(bounds);
      // filterBufferStatus('ALL');
    }

    if (
      !_.isEmpty(trackersImutable) &&
      !_.isEmpty(mapConst) &&
      !_.isEmpty(mapsConst)
    ) {
      getBounds();
    }
  }, [trackersImutable]);

  function mapLoad(map, maps, Trackers) {
    const bounds = getMapBounds(map, maps, Trackers);
    setMapsConst(maps);
    setMapConst(map);
    map.fitBounds(bounds);
  }

  function setDetailTracker(tracker) {
    history.push(`detailCar/${tracker.tracker_id}`);
  }

  return (
    <>
      {!auth.passenger && (
        <>
          <StatusContainer>
            <ButtonStatus
              selected={filterActive === 'ALL'}
              onClick={() => filterBufferStatus('ALL')}
              type="button"
            >
              <span>{trackersImutable.length}</span>
              <img src={totalIcon} alt="Todos" />
            </ButtonStatus>
            <ButtonStatus
              selected={filterActive === 'ON'}
              onClick={() => filterBufferStatus('ON')}
              type="button"
            >
              <span>
                {trackersImutable.filter((item) => item.status === 'ON').length}
              </span>
              <img src={runIcon} alt="Ligado(s)" />
            </ButtonStatus>
            <ButtonStatus
              selected={filterActive === 'OFF'}
              onClick={() => filterBufferStatus('OFF')}
              type="button"
            >
              <span>
                {
                  trackersImutable.filter((item) => item.status === 'OFF')
                    .length
                }
              </span>
              <img src={stopIcon} alt="Parado(s)" />
            </ButtonStatus>
            <ButtonStatus
              selected={filterActive === 'DANGER'}
              onClick={() => filterBufferStatus('DANGER')}
              type="button"
            >
              <span>
                {
                  trackersImutable.filter((item) => item.status === 'DANGER')
                    .length
                }
              </span>
              <img src={alertIcon} alt="Alerta(s)" />
            </ButtonStatus>
            <ButtonStatus
              selected={filterActive === 'DISCONNECTED'}
              onClick={() => filterBufferStatus('DISCONNECTED')}
              type="button"
            >
              <span>
                {
                  trackersImutable.filter(
                    (item) => item.status === 'DISCONNECTED'
                  ).length
                }
              </span>
              <img src={noSignalIcon} alt="Sem comunicação" />
            </ButtonStatus>
            <ButtonStatus
              type="button"
              onClick={() => filterBufferStatus('SLEEP')}
            >
              <span>
                {
                  trackersImutable.filter((item) => item.status === 'SLEEP')
                    .length
                }
              </span>
              <img src={sleepIcon} alt="Sleep" />
            </ButtonStatus>
          </StatusContainer>
        </>
      )}

      <GoogleMap loadMap={(map, maps) => mapLoad(map, maps, trackers)}>
        {!_.isEmpty(trackers) &&
          trackers.map((tracker) => {
            let marker;
            let alert;
            if (tracker.status === 'DANGER') {
              marker = markerAlert;
            } else if (tracker.ignition) {
              marker = markerRun;
            } else {
              marker = markerStop;
            }

            if (tracker.status === 'DISCONNECTED') {
              alert = 'DISCONNECTED';
            } else if (tracker.alert_on) {
              alert = 'ALERT';
            } else {
              alert = null;
            }

            return (
              <Marker
                key={tracker.tracker_id}
                alert={alert}
                rotate={tracker.angle}
                lat={tracker.lat}
                lng={tracker.lng}
                onClick={() => setDetailTracker(tracker)}
              >
                <div>{tracker.label}</div>
                <img src={marker} alt="carros" />
              </Marker>
            );
          })}
      </GoogleMap>
    </>
  );
}
