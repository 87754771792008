import {
  trafficControl,
  mapControl,
  referencePointControl,
  zoneControl,
} from './controls';

export function initControls(map, measureTool) {
  const centerControlDiv = document.createElement('div');
  new mapControl(centerControlDiv, map);

  centerControlDiv.index = 1;
  const isDetail = window.location.pathname.indexOf('detailCar') !== -1;
  map.controls[
    isDetail
      ? window.google.maps.ControlPosition.LEFT_BOTTOM
      : window.google.maps.ControlPosition.RIGHT_BOTTOM
  ].push(centerControlDiv);

  const trafficControlDiv = document.createElement('div');
  new trafficControl(trafficControlDiv, map);

  trafficControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
    trafficControlDiv
  );

  const referencePointControlDiv = document.createElement('div');
  new referencePointControl(referencePointControlDiv, map);

  referencePointControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
    referencePointControlDiv
  );

  const zoneControlDiv = document.createElement('div');
  new zoneControl(zoneControlDiv, map);

  zoneControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
    zoneControlDiv
  );
}
