import styled from 'styled-components';

export const Container = styled.div`
  grid-area: actions;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  > button {
    height: 70px;
    width: 70px;
    border: none;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    height: 62px;
    border-radius: 10px;
    :disabled {
      opacity: 10%;
    }
    /* box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px; */

    > img {
      height: 30px;
    }
  }
`;
