// import { Alert } from 'react-native';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* profile() {
  try {
    const response = yield call(api.get, 'profile');

    const { data } = response;

    yield put({
      type: Types.USER_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: Types.USER_FAILURE,
    });
  }
}

export function* update(payload) {
  try {
    const { user } = payload;
    const { data } = yield call(api.put, `/profile`, user);

    toast.success('Perfil Atualizado com sucesso!');

    yield put({
      type: Types.USER_SUCCESS,
      payload: { ...data, password: undefined },
    });
  } catch (error) {
    yield put({
      type: Types.USER_FAILURE,
    });
  }
}

export default all([
  takeLatest(Types.USER_REQUEST, profile),
  takeLatest(Types.USER_UPDATE, update),
]);
