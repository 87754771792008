import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  /* display: grid; */
  /* grid-template-rows: 1fr 1fr; */
`;

export const Content = styled.div`
  height: 220px;
  margin-left: 10px;
  width: calc(100% - 20px);
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  position: absolute;
  z-index: 3;
  bottom: 10px;
  display: grid;
  grid-template-rows: 50px 70px;
  grid-gap: 10px;
  > header {
    display: grid;
    grid-template-columns: 40px 1fr 50px;
    align-items: center;
    grid-gap: 10px;

    > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > strong {
        font-size: 25px;
        > div {
          font-size: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      > small {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    > img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
    > div {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      > strong {
        font-size: 18px;
      }
      > small {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  > aside {
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-gap: 10px;
  }
`;

export const Item = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  > div {
    display: flex;
    align-items: center;
    margin: 2.5px;
    > img {
      height: 20px;
      width: 20px;
    }
    > small {
      padding-left: 5px;
      font-size: 12px;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  > button {
    background: none;
    border: 0;
    > img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const GeoContainer = styled.a`
  position: absolute;
  width: 200px;
  height: 80px;
  background: #245378;
  opacity: 0.8;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  z-index: 999;
  top: 10px;
  left: 10px;
  text-decoration: none;

  > img {
    height: 40px;
  }
  > div {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    color: #fff;
    padding: 5px 10px;
  }
`;
