import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as NotificationActions } from '~/store/modules/notification/duck';

import { Clients, Trackers, Li, Notification, Box } from './styles';

function List() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [trackerSelected, setTrackerSelected] = useState({ tracker_id: '' });
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const loading = useSelector((state) => state.notification.loading);

  function showDetail(tracker) {
    if (tracker.tracker_id === trackerSelected.tracker_id) {
      setVisible(false);
      setTrackerSelected({});
    } else {
      setVisible(true);
      setTrackerSelected(tracker);
    }
  }

  function notificationContainers() {
    if (loading) {
      return <Box>Carregando...</Box>;
    }
    if (_.isEmpty(notifications)) {
      return <Box>Sem dados para exibir</Box>;
    }
    return notifications.map((alert, index) => (
      <Clients key={index}>
        <header>{alert.status}</header>
        {alert.trackers.map((tracker, i) => (
          <>
            <Trackers
              key={i}
              selected={tracker.tracker_id === trackerSelected.tracker_id}
            >
              <div>{`O ${tracker.tracker_id} tem ${tracker.notifications.length} alerta(s)!`}</div>
              <button type="button" onClick={() => showDetail(tracker)}>
                {tracker.tracker_id === trackerSelected.tracker_id ? (
                  <MdExpandLess color="#fff" size={20} />
                ) : (
                  <MdExpandMore color="#fff" size={20} />
                )}
              </button>
            </Trackers>
            {tracker.tracker_id === trackerSelected.tracker_id && (
              <Notification>
                <header>
                  <div>
                    {trackerSelected.notifications[0] &&
                      `${trackerSelected.notifications[0].brand} ${trackerSelected.notifications[0].model} - ${trackerSelected.notifications[0].color}`}
                  </div>
                  <br />
                  <div>
                    {trackerSelected.notifications[0] &&
                      trackerSelected.notifications[0].contact_emergency &&
                      trackerSelected.notifications[0].phone_emergency &&
                      `${trackerSelected.notifications[0].contact_emergency} - ${trackerSelected.notifications[0].phone_emergency}`}
                  </div>
                </header>
                <ul>
                  {trackerSelected.notifications.map((notification, index) => (
                    <Li key={index} checked={notification.checked}>
                      {notification.checked ? (
                        <div>
                          <div>
                            <strong>Alerta: </strong>
                            <span>{`No dia ${moment(notification.date).format(
                              'DD/MM/YYYY HH:mm'
                            )} o veiculo regitrou ${
                              notification.flags.length > 1
                                ? `as seguintes ocorrências:`
                                : `a seguinte ocorrência:`
                            } ${_.join(notification.flags, ',')}.`}</span>
                          </div>
                          <div>
                            <strong>Procedimento: </strong>
                            <span>{notification.procedure}</span>
                          </div>
                        </div>
                      ) : (
                        `No dia ${moment(notification.date).format(
                          'DD/MM/YYYY HH:mm'
                        )} o veiculo regitrou ${
                          notification.flags.length > 1
                            ? `as seguintes ocorrencias:`
                            : `a seguinte ocorrencia:`
                        } ${_.join(notification.flags, ',')}.`
                      )}
                    </Li>
                  ))}
                </ul>
              </Notification>
            )}
          </>
        ))}
      </Clients>
    ));
  }

  return <div>{notificationContainers()}</div>;
}

export default List;
