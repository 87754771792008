// import { Alert } from 'react-native';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* sendCommand(payload) {
  try {
    yield call(api.post, 'commands', payload.data);

    toast.success('Comando enviado com sucesso!');
    yield put({
      type: Types.SUCCESS_COMMAND,
    });
  } catch (error) {
    // Alert.alert(
    //   'Falha na conexão',
    //   'Confira a sua conexão e reinice o aplicativo'
    // );
    toast.error('Erro ao enviar comando!');
    yield put({
      type: Types.FAILURE_COMMAND,
    });
  }
}

export function* commandRequest({ id }) {
  try {
    const { data: tracker } = yield call(api.get, `/trackers/${id}`);
    const { data: buffer } = yield call(api.get, `/buffer?tracker_id=${id}`);

    const { data: commands } = yield call(
      api.get,
      `/commands?tracker_id=${id}`
    );
    const { data: commandList } = yield call(
      api.get,
      `/listcommands?tracker_id=${id}`
    );
    yield put({
      type: Types.FETCH_COMMAND_SUCCESS,
      tracker,
      commands,
      buffer: buffer[0],
      commandList: commandList[0],
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_COMMAND_FAILURE });
  }
}

export function* commandBufferSaga({ id }) {
  try {
    const { data: buffer } = yield call(api.get, `/buffer?tracker_id=${id}`);

    yield put({
      type: Types.FETCH_COMMAND_BUFFER_SUCCESS,
      buffer: buffer[0],
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_COMMAND_BUFFER_FAILURE });
  }
}

export function* createCommand({ body }) {
  try {
    yield call(api.post, '/commands', body);
    toast.success('Comando enviado com sucesso!');

    yield put({
      type: Types.FETCH_CREATE_SUCCESS,
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_CREATE_FAILURE });
  }
}

export default all([
  takeLatest(Types.SEND_COMMAND, sendCommand),
  takeLatest(Types.FETCH_COMMAND_REQUEST, commandRequest),
  takeLatest(Types.FETCH_COMMAND_BUFFER_REQUEST, commandBufferSaga),
  takeLatest(Types.CREATE_COMMAND, createCommand),
]);
