import React from 'react';

import List from './List';
import { Container } from './styles';

function Block() {
  return (
    <Container>
      <List />
    </Container>
  );
}

export default Block;
