import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdClear, MdDone, MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '~/components/Buttons/animationLoading';
import MaskedInput from '~/components/Inputs/MaskedInput';
import SimpleInput from '~/components/Inputs/SimpleInput';
import { DateStyle } from '~/pages/Daily/styles';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as BlockActions } from '~/store/modules/block/duck';

import Modal from './ModalContainer';
import {
  Container,
  Button,
  Header,
  Body,
  InputsContainer,
  LabelCar,
  CarContainer,
  CarHeader,
  InputPlate,
} from './styles';

function Form({ match }) {
  const dispatch = useDispatch();
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [carsImutable, setCarsImutable] = useState([]);
  const [carsSelected, setCarsSelected] = useState([]);
  const formik = useFormik({
    initialValues: {
      cars: [],
      date_start: '',
      name: '',
      date_end: '',
    },

    onSubmit: ({ cars, name, date_end, date_start }) => {
      const id_trackers = cars
        .filter((car) => car.checked)
        .map((car) => car.id);
      if (_.isEmpty(id_trackers)) {
        toast.warn('Selecione algum carro para o bloqueio agendado!');
      } else if (moment(date_start).isAfter(date_end)) {
        toast.warn(
          'A data de bloqueio não pode ser posterior a data de desbloquio!'
        );
      } else if (moment().isAfter(date_start)) {
        toast.warn('A data de bloqueio não pode antes da data atual!');
      } else {
        setParams({
          name,
          date_start: moment(date_start).format('YYYY-MM-DDTHH:mm:ssZ'),
          date_end: moment(date_end).format('YYYY-MM-DDTHH:mm:ssZ'),
          id_trackers,
        });
        dispatch(BlockActions.setModal());
      }
    },
  });
  useEffect(() => {
    async function getListComands() {
      try {
        const { data } = await api.get('/listcommands');
        const newData = data
          .filter((tracker) => {
            if (!_.isEmpty(tracker.commands)) {
              return !_.isEmpty(
                tracker.commands.filter((command) => command.type === 'BLOCK')
              );
            }
          })
          .map((tracker) => ({ ...tracker, checked: false }));
        // setCarsBlock(newData);
        formik.setFieldValue('cars', newData);
        setCarsImutable(newData);

        setLoading(false);
      } catch (error) {
        formik.setFieldValue('cars', []);
        setLoading(false);
      }
    }
    setLoading(true);
    getListComands();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(carsImutable)) {
      const newCar = carsImutable.map((car) => {
        if (carsSelected.indexOf(car.id) !== -1) {
          return { ...car, checked: true };
        }
        return { ...car, checked: false };
      });
      formik.setFieldValue('cars', newCar);
    }
  }, [carsImutable]);

  useEffect(() => {
    async function getListComand(id) {
      const { data } = await api.get(`/schedules/${id}`);
      data[0].date_start = moment(data[0].date_start).toDate();
      data[0].date_end = moment(data[0].date_end).toDate();
      setCarsSelected(data[0].id_trackers);
      formik.setValues({ ...formik.values, ...data[0] });
    }
    if (match.params.id) {
      getListComand(match.params.id);
    }
  }, [match]);

  function selectAllCars() {
    formik.setFieldValue(
      'cars',
      formik.values.cars.map((car) => ({ ...car, checked: true }))
    );
  }

  function backToList() {
    history.push('/block');
  }

  return (
    <>
      <Container onSubmit={formik.handleSubmit}>
        <Header>
          <header>Criar bloquei agendado</header>
          <div>
            {match.params.id && (
              <Button
                type="button"
                bg="#E33939"
                onClick={() =>
                  dispatch(BlockActions.deleteBlock(match.params.id))
                }
              >
                <MdDelete color="#F9FCFE" size={20} />
              </Button>
            )}
            <Button type="button" bg="#E33939" onClick={() => backToList()}>
              <MdClear color="#F9FCFE" size={20} />
            </Button>
            <Button type="submit" bg="#03669f">
              <MdDone color="#F9FCFE" size={20} />
            </Button>
          </div>
        </Header>
        <Body>
          <InputsContainer>
            <SimpleInput
              required
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Nome do agendamento"
            />
            <DateStyle
              required
              showTimeInput
              dateFormat="dd/MM/yyyy HH:mm:ss"
              placeholderText="Data de bloqueio"
              selected={formik.values.date_start}
              onChange={(value) =>
                formik.setFieldValue('date_start', moment(value).toDate())
              }
              locale={pt}
              customInput={<MaskedInput mask="99/99/9999 99:99" />}
            />
            <DateStyle
              required
              showTimeInput
              placeholderText="Data de desbloqueio"
              dateFormat="dd/MM/yyyy HH:mm:ss"
              selected={formik.values.date_end}
              onChange={(value) =>
                formik.setFieldValue('date_end', moment(value).toDate())
              }
              locale={pt}
              customInput={<MaskedInput mask="99/99/9999 99:99" />}
            />
          </InputsContainer>
          {loading ? (
            <Loading />
          ) : (
            <CarContainer>
              <CarHeader>
                {/* <InputPlate
                  plate
                  mask="aaa-9*999"
                  placeholder="Pesquise pela placa"
                /> */}
                <div style={{ width: '100%' }} />
                <button type="button" onClick={() => selectAllCars()}>
                  Selecionar todos
                </button>
              </CarHeader>
              {formik.values.cars.map((car, index) => (
                <LabelCar key={car.id} checked={car.checked}>
                  <input
                    id={`car${index}`}
                    type="checkbox"
                    checked={car.checked}
                    onChange={() =>
                      formik.setFieldValue(
                        `cars[${index}].checked`,
                        !car.checked
                      )
                    }
                  />
                  <label htmlFor={`car${index}`}>{car.plate}</label>
                </LabelCar>
              ))}
            </CarContainer>
          )}
        </Body>
      </Container>
      <Modal params={params} cars={formik.values.cars} id={match.params.id} />
    </>
  );
}

export default Form;
