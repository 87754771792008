import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MaskedInput from '~/components/Inputs/MaskedInput';
import PasswordInput from '~/components/Inputs/PasswordInput';
import { Creators as AuthActions } from '~/store/modules/auth/duck';

import { SubmitButton } from './styles';

export default function SignIn() {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);

  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
    },
    onSubmit: ({ cpf, password }) => {
      dispatch(AuthActions.signInRequest(cpf, password));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2>Login</h2>
      <MaskedInput
        color="#fff"
        id="cpf"
        placeholder="CPF"
        name="cpf"
        type="tel"
        required
        onChange={formik.handleChange}
        value={formik.values.cpf}
        mask="999.999.999-99"
      />
      <PasswordInput
        color="#fff"
        id="password"
        placeholder="Senha"
        name="password"
        type="password"
        required
        onChange={formik.handleChange}
        value={formik.values.password}
      />
      <SubmitButton loading={loading}>
        {loading ? <FaSpinner color="#fff" size={14} /> : 'Entrar'}
      </SubmitButton>
      <Link to="/forgot">Esqueceu a senha?</Link>
    </form>
  );
}

SignIn.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
