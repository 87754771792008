import styled from 'styled-components';

export const Container = styled.div``;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 60px;
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  top: 60px;
  margin-left: 10px;
  width: calc(100% - 20px);
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  padding: 10px;
`;

export const Content = styled.div`
  display: grid;
  position: absolute;
  height: 150px;
  margin-left: 10px;
  width: calc(100% - 20px);
  bottom: 50px;
  z-index: 3;
  grid-template-columns: 1fr 140px;
  grid-template-areas: 'car actions';
  padding: 10px;
`;
